import { twc } from "react-twc";
import FormTagSelector from "./FormTagSelector";
import TokenSelectInput from "./TokenSelectInput";
import TokenAmountInput from "./TokenAmountInput";
import StakeButton from "./StakeButton";
import SymbolInputContext from "../../context/SymbolInputContext";
import TokenInputContext from "../../context/TokenInputContext";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import useAllstakeSdk from "../../hooks/useAllstakeSdk";
import { useWallet } from "@solana/wallet-adapter-react";
import Big from "big.js";
import {
  extractToTokenName,
  isDigit,
  isChainNativeToken,
  extractToTokenNameByName,
} from "../../utils/regex";
import { useEffect, useMemo, useState } from "react";
import AllstakeModal from "../modal";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import RestakeTabContext from "../../context/RestakeTabContext";
import RefreshBalanceContext from "../../context/RefreshBalanceContext";
import useWithdrawAmount from "../../hooks/useWithdrawAmount";
import { useLocation, useNavigate } from "react-router-dom";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import {
  Amount,
  AnchorProvider,
  BigNumber,
  MultiSendAdapter,
  stakeToMsol,
  throwReceiptErrorsFromOutcomes,
} from "allstake-sdk";
import useQuery from "../../hooks/useQuery";
import { getNear, isWebWallet } from "../../utils/wallet";
import { getMethodNamesFromArgs } from "../../utils/near";
import { UNSTAKE_INTERVAL, TGas } from "../../constants";
import { useTranslation } from "react-i18next";
import { SymbolInput } from "../../config/type";
import {
  stakeToLinear,
  stakeToStnear,
} from "allstake-sdk/src/near/utils/contract";
import clsx from "clsx";
import config from "../../config";
import { base64 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { connection, getTokenAmount } from "../../utils/rpc";
import { formatToReadableError } from "../../utils/error";
import {
  approve,
  checkQueueWithdrawInsufficientAllowance,
  checkStrategyInsufficientAllowance,
  getErc20Balance,
  waitForTransactionReceiptRecursion,
} from "../../utils/erc20";
import { Address } from "viem";
import { usePublicClient, useAccount, useWalletClient } from "wagmi";
import { SECOND, sleep } from "../../utils/time";
import { TFunction } from "i18next";
import toLocaleString from "../../utils/toLocaleString";
import { getPairPrice } from "../../utils/price";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { balanceToShares } from "allstake-sdk/dist/ethereum";
import useShownBalance from "../../hooks/useShownBalance";
import useChain from "../../hooks/useChain";
import useATokenAddress from "../../hooks/useATokenAddress";
import { resetWagmiState } from "../../utils/wagmi";

const { useSymbolInputTracked } = SymbolInputContext;
const { useTokenInputTracked } = TokenInputContext;
const { useRestakeTabTracked } = RestakeTabContext;
const { useRefreshBalanceTracked } = RefreshBalanceContext;

function RestakingForm() {
  const { accountId } = useWalletSelector();
  const [symbolInput, setSymbolInput] = useSymbolInputTracked();
  const [tokenInput, setTokenInput] = useTokenInputTracked();
  const wallet = useWallet();
  const allstakeSdk = useAllstakeSdk();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [pairPrice, setPairPrice] = useState(BigNumber(0));
  const [showModal, setShowModal] = useState<
    | "deposit_success"
    | "stake_near_success"
    | ""
    | "error"
    | "warn"
    | "confirmUnstake"
    | "goToWithdraw"
    | "restake_success"
    | "addTokenToWalletSuccess"
    | "addTokenToWalletFailed"
    | undefined
  >();
  const [error, setError] = useState("");
  const [restakeTab] = useRestakeTabTracked();
  const [, setRefreshBalance] = useRefreshBalanceTracked();
  const shownBalance = useShownBalance();
  const insufficientBalance = !!(
    shownBalance &&
    isDigit(shownBalance) &&
    isDigit(tokenInput) &&
    Big(tokenInput).gt(shownBalance)
  );
  const { withdrawAmount, disabledWithdrawDueTime, dataCompleted } =
    useWithdrawAmount();
  const navigate = useNavigate();
  const chain = useChain();
  const { modal } = useWalletSelector();
  const { open: ethModalOpen } = useWeb3Modal();

  const { t } = useTranslation();

  const query = useQuery();
  const symbol = query.get("symbol");
  const txHashes = query.get("transactionHashes");
  const errorCode = query.get("errorCode");
  const { pathname } = useLocation();

  const ethAccount = useAccount();
  const publicClient = usePublicClient();
  const { selector } = useWalletSelector();

  const provider = useMemo(
    () => new AnchorProvider(connection, wallet as any, {}),
    [wallet],
  );

  const { data: walletClient } = useWalletClient();

  useEffect(() => {
    if (!allstakeSdk || !symbolInput) return;
    const stakeToName = extractToTokenName(symbolInput);
    getPairPrice(
      allstakeSdk,
      symbolInput.address,
      symbolInput.symbol,
      symbolInput.address,
      stakeToName,
    ).then((price) => {
      setPairPrice(price);
    });
  }, [symbolInput]);

  const onClickDeposit = async () => {
    if (!symbolInput) return;
    if (symbolInput.chain === "solana") {
      if (
        !allstakeSdk ||
        !allstakeSdk.solana?.strategyManagerProgram ||
        !wallet?.publicKey
      ) {
        return;
      }
      setLoading(true);
      try {
        let amount = Amount.parse(tokenInput, symbolInput.decimals);
        if (isChainNativeToken(symbolInput.name)) {
          setLoadingModal(true);
          const stakeToName = extractToTokenName(symbolInput);
          if (stakeToName === "mSOL") {
            const beforeBalance = (
              await getTokenAmount(
                symbolInput.address,
                wallet.publicKey.toBase58(),
              )
            ).amount;
            await stakeToMsol(provider, new BN(amount));
            const afterBalance = (
              await getTokenAmount(
                symbolInput.address,
                wallet.publicKey.toBase58(),
              )
            ).amount;
            amount = String(afterBalance - beforeBalance);
            setLoadingModal(false);
            navigate(`/?symbol=${stakeToName}`);
            const token =
              config.restakingTokens.find(
                (token) => token.name === stakeToName,
              ) ?? config.restakingTokens[0];
            const formattedAmount = Amount.format(amount, token.decimals);
            setTimeout(() => {
              setRefreshBalance(true);
              setTokenInput(formattedAmount);
              setShowModal("restake_success");
            }, SECOND * 1);
            return;
          } else if (stakeToName === "jitoSOL") {
            // TODO: later
          }
        }

        await allstakeSdk.solana.strategyManagerProgram.deposit(
          new PublicKey(symbolInput.address),
          new BN(amount),
        );
        setShowModal("deposit_success");
        setTokenInput("");
      } catch (e) {
        setShowModal("error");
        setError(formatToReadableError(e, t));
        throw e;
      } finally {
        setRefreshBalance(true);
        setLoading(false);
      }
    } else if (symbolInput.chain === "near") {
      if (
        !allstakeSdk ||
        !allstakeSdk.near?.strategyManagerContract ||
        !accountId
      ) {
        return;
      }
      setLoading(true);
      try {
        let amount = Amount.parse(tokenInput, symbolInput.decimals);
        if (isChainNativeToken(symbolInput.name)) {
          const stakeToName = extractToTokenName(symbolInput);
          if (!(await isWebWallet())) {
            setLoadingModal(true);
          }
          if (stakeToName === "LiNEAR") {
            amount = await stakeToLinear(
              MultiSendAdapter.fromWalletSelector(selector),
              symbolInput.address,
              amount,
            );
          } else if (stakeToName === "stNEAR") {
            amount = await stakeToStnear(
              MultiSendAdapter.fromWalletSelector(selector),
              symbolInput.address,
              amount,
            );
          }

          if (!(await isWebWallet())) {
            setLoadingModal(false);
          }
          navigate(`/?symbol=${stakeToName}`);
          const targetSymbol = config.restakingTokens.find(
            (token) => token.name === stakeToName,
          );
          if (targetSymbol) {
            setSymbolInput(targetSymbol);
          }
          const token =
            config.restakingTokens.find(
              (token) => token.name === stakeToName,
            ) ?? config.restakingTokens[0];
          const formattedAmount = Amount.format(amount, token.decimals);
          setTimeout(() => {
            setRefreshBalance(true);
            setTokenInput(formattedAmount);
            setShowModal("restake_success");
          }, SECOND * 1);
          return;
        }
        const strategies =
          await allstakeSdk.near.strategyManagerContract.getStrategies({});
        const strategy = strategies.find(
          (strategy) => strategy.underlying_token === symbolInput.address,
        );
        if (!strategy) {
          setLoading(false);
          setShowModal("error");
          setError(t("error.strategyNotExist"));
          return;
        }
        await allstakeSdk.near.strategyManagerContract.depositIntoStrategy({
          strategyId: strategy.id,
          tokenId: symbolInput.address,
          amount,
          gas: TGas.times(100).toFixed(), // 100 TGas
        });
        setShowModal("deposit_success");
        setTokenInput("");
      } catch (e) {
        if (!(await isWebWallet())) {
          setShowModal("error");
        }
        setError(formatToReadableError(e, t));
        throw e;
      } finally {
        if (!(await isWebWallet())) {
          setLoadingModal(false);
        }
        setRefreshBalance(true);
        setLoading(false);
      }
    } else if (symbolInput.chain === "eth") {
      if (
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract ||
        !allstakeSdk.ethereum.uiDataProviderContract ||
        !publicClient ||
        !ethAccount.address
      ) {
        return;
      }
      setLoading(true);
      try {
        const strategyData =
          await allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
            symbolInput.address,
          );
        if (
          await checkStrategyInsufficientAllowance(
            symbolInput,
            strategyData,
            ethAccount.address,
            tokenInput,
          )
        ) {
          await approve(
            symbolInput.address as Address,
            strategyData.strategyAddress as Address,
            publicClient,
          );
        }
        if (
          await checkStrategyInsufficientAllowance(
            symbolInput,
            strategyData,
            ethAccount.address,
            tokenInput,
          )
        ) {
          setError(t("error.insufficientAllowance"));
          setShowModal("error");
          return;
        }
        const txHash =
          await allstakeSdk.ethereum.strategyManagerContract.depositIntoStrategy(
            strategyData.strategyAddress,
            BigInt(Amount.parse(tokenInput, symbolInput.decimals)),
          );
        await waitForTransactionReceiptRecursion(publicClient, txHash);
        setShowModal("deposit_success");
        setTokenInput("");
        setRefreshBalance(true);
      } catch (e) {
        setShowModal("error");
        setError(formatToReadableError(e, t));
        throw e;
      } finally {
        setLoading(false);
      }
    }
  };

  async function parseTransaction() {
    if (errorCode) {
      navigate(`${pathname}${symbol ? `?symbol=${symbol}` : ""}`);
    } else {
      if (!txHashes || !accountId) return;
      if (txHashes) {
        const near = await getNear();
        const navigatePathname = pathname;
        try {
          const outcomes = await Promise.all(
            txHashes
              .split(",")
              .map(
                async (txHash) =>
                  await near.connection.provider.txStatus(txHash, accountId),
              ),
          );
          throwReceiptErrorsFromOutcomes(outcomes);
          const methodNames = await getMethodNamesFromArgs(
            txHashes.split(","),
            accountId,
          );
          if (
            methodNames.find((methodName) =>
              methodName.startsWith("queue_withdrawals"),
            )
          ) {
            // It can only be obtained from the url because the received id is incorrect
            navigate(
              `${navigatePathname}?tab=unstake${symbol ? `&symbol=${symbol}` : ""}`,
            );
          } else if (
            methodNames.find((methodName) =>
              methodName.startsWith("ft_transfer_call"),
            )
          ) {
            const methodName = methodNames.find((methodName) =>
              methodName.startsWith("ft_transfer_call"),
            )!;
            // It cannot be obtained by url, because near -> linear does not take a url params
            navigate(`${navigatePathname}?symbol=${methodName.split(",")[1]}`);
          } else {
            navigate(`${navigatePathname}${symbol ? `?symbol=${symbol}` : ""}`);
          }
          if (
            methodNames.find(
              (methodName) =>
                methodName.startsWith("ft_transfer_call") ||
                methodName.startsWith("queue_withdrawals"),
            )
          ) {
            setShowModal("deposit_success");
          } else if (
            methodNames.find((methodName) =>
              methodName.startsWith("deposit_and_stake"),
            )
          ) {
            const methodName = methodNames.find((methodName) =>
              methodName.startsWith("deposit_and_stake"),
            );
            if (methodName) {
              const splits = methodName.split(",");
              const tokenName = splits[1];
              const updateSymbolInput = config.restakingTokens.find(
                (token) => token.name === tokenName,
              );
              if (tokenName && updateSymbolInput) {
                navigate(`/?symbol=${updateSymbolInput.name}`);
                try {
                  const value = JSON.parse(
                    base64
                      .decode((outcomes[0].status as any).SuccessValue)
                      .toString(),
                  );
                  const formattedValue = Amount.format(
                    value,
                    updateSymbolInput.decimals,
                  );
                  setTimeout(() => {
                    setTokenInput(formattedValue);
                    setShowModal("restake_success");
                  }, 1 * SECOND);
                } catch (e) {
                  console.error(e);
                }
                setShowModal("stake_near_success");
              }
            }
          }
        } catch (e) {
          if (
            e instanceof Error &&
            /Transaction\s([a-zA-Z0-9]+)\sdoesn't\sexist/.test(e.message)
          ) {
            console.warn(`${e.message}, retring in 3 seconds`);
            await sleep(3 * SECOND);
            await parseTransaction();
          } else {
            setShowModal("error");
            setError(formatToReadableError(e, t));
          }
          throw e;
        }
      }
    }
  }

  useEffect(() => {
    parseTransaction();
  }, [symbolInput, txHashes, accountId]);

  const depositButtonText = getDepositButtonText(
    symbolInput,
    insufficientBalance,
    t,
  );

  const aToken = useATokenAddress();

  if (!symbolInput || !chain) return null;
  return (
    <RestakingFormContainer>
      <FormTagSelector />
      <TokenSelectInput />
      <TokenAmountInput />
      <div className="flex flex-col justify-center items-center">
        {!chain.isSignedIn && chain.chainId === "solana" && (
          <WalletMultiButton style={{ width: "260px", height: "56px" }} />
        )}
        {!chain.isSignedIn && chain.chainId === "near" && (
          <ConnectButton
            onClick={() => {
              modal.show();
            }}
          >
            {t("header.selectWallet")}
          </ConnectButton>
        )}
        {!chain.isSignedIn && chain.chainId === "eth" && (
          <ConnectButton
            onClick={() => {
              if (!walletClient && ethAccount.isConnected) {
                // reset wagmi state
                resetWagmiState();
                return;
              }
              ethModalOpen();
            }}
          >
            {t("header.selectWallet")}
          </ConnectButton>
        )}
        {chain.isSignedIn && restakeTab === "deposit" && (
          <StakeButton
            className={clsx(depositButtonText.length > 25 && "!w-[320px]")}
            disabled={
              !isDigit(tokenInput) ||
              Big(tokenInput).lt(symbolInput.minAmount) ||
              insufficientBalance
            }
            loading={loading}
            onClick={onClickDeposit}
          >
            {depositButtonText}
          </StakeButton>
        )}
        {chain.isSignedIn && restakeTab === "unstake" && (
          <StakeButton
            disabled={
              !isDigit(tokenInput) ||
              Big(tokenInput).lte(0) ||
              insufficientBalance ||
              (chain.chainId === "eth" && !dataCompleted)
            }
            loading={loading}
            onClick={async () => {
              if (
                withdrawAmount.raw.gt(new BN(0)) &&
                !disabledWithdrawDueTime
              ) {
                setShowModal("goToWithdraw");
              } else {
                setShowModal("confirmUnstake");
              }
            }}
          >
            {insufficientBalance ? "Insufficient Balance" : "Unstake"}
          </StakeButton>
        )}
        {restakeTab === "unstake" && (
          <UnstakedText>
            {t("restake.unstakeInDays", {
              days: UNSTAKE_INTERVAL,
            })}
          </UnstakedText>
        )}
      </div>
      {loadingModal && (
        <AllstakeModal
          varient="loading"
          onRequestClose={() => setLoadingModal(false)}
          title=""
          description={t("modal.stakeTokenAToTokenB", {
            tokenA: `${toLocaleString(tokenInput, 4)} <img class="inline-block h-5 w-5" src="${symbolInput.icon}" /> ${symbolInput.symbol}`,
            tokenB: `${
              pairPrice.eq(0) || !isDigit(tokenInput)
                ? "-"
                : toLocaleString(
                    pairPrice.times(BigNumber(Number(tokenInput))).toFixed(),
                    4,
                  )
            } <img class="inline-block h-5 w-5" src="${config.restakingTokens.find((token) => token.name === extractToTokenName(symbolInput))?.icon}" /> ${extractToTokenName(symbolInput)}`,
          })}
          buttons={[]}
        />
      )}
      {showModal === "goToWithdraw" && (
        <AllstakeModal
          varient="warn"
          onRequestClose={() => setShowModal(undefined)}
          title="modal.goToWithdrawTitle"
          description={t("modal.goToWithdrawDescription", {
            symbol: symbolInput.symbol,
          })}
          buttons={[
            {
              varient: "outline",
              text: "modal.back",
              onClick: () => setShowModal(undefined),
            },
            {
              varient: "primary",
              text: "modal.goToWithdraw",
              onClick: () => {
                const newQuery = new URLSearchParams();
                const symbol = query.get("symbol");
                const chain = query.get("chain");
                if (symbol) {
                  newQuery.set("symbol", extractToTokenNameByName(symbol));
                } else if (chain) {
                  newQuery.set("chain", chain);
                }
                navigate(
                  `/portfolio${
                    newQuery.toString() !== "" ? `?${newQuery.toString()}` : ""
                  }`,
                );
              },
            },
          ]}
        />
      )}
      <div style={{ display: "none" }}>{chain.chainId}</div>
      {showModal === "confirmUnstake" && (
        <AllstakeModal
          varient="warn"
          onRequestClose={() => setShowModal(undefined)}
          title="modal.startUnstakeTitle"
          description={t("modal.startUnstakeDescription", {
            days: UNSTAKE_INTERVAL,
          })}
          buttons={[
            {
              varient: "outline",
              text: "modal.cancel",
              disabled: loading,
              onClick: () => setShowModal(undefined),
            },
            {
              varient: "primary",
              text: "modal.confirm",
              loading: loading,
              onClick: async () => {
                if (chain.chainId === "solana") {
                  if (
                    !allstakeSdk ||
                    !allstakeSdk.solana ||
                    !allstakeSdk.solana.strategyManagerProgram ||
                    !wallet?.publicKey
                  ) {
                    return;
                  }
                  setLoading(true);
                  try {
                    await allstakeSdk.solana.strategyManagerProgram.queueWithdraw(
                      new PublicKey(symbolInput.address),
                      new BN(Amount.parse(tokenInput, symbolInput.decimals)),
                    );
                    setShowModal("deposit_success");
                    setRefreshBalance(true);
                    setTokenInput("");
                  } catch (e) {
                    setShowModal("error");
                    setError(formatToReadableError(e, t));
                    throw e;
                  } finally {
                    setLoading(false);
                  }
                } else if (chain.chainId === "near") {
                  if (
                    !allstakeSdk ||
                    !allstakeSdk.near ||
                    !allstakeSdk.near.strategyManagerContract ||
                    !accountId
                  ) {
                    return;
                  }
                  setLoading(true);
                  try {
                    const strategies =
                      await allstakeSdk.near.strategyManagerContract.getStrategies(
                        {},
                      );
                    const strategy = strategies.find(
                      (strategy) =>
                        strategy.underlying_token === symbolInput.address,
                    );
                    if (!strategy) {
                      return;
                    }
                    await allstakeSdk.near.strategyManagerContract.queueWithdrawals(
                      {
                        queuedWithdrawalParams: [
                          {
                            withdrawer: accountId,
                            strategies: [strategy.id],
                            shares: [
                              Amount.parse(tokenInput, symbolInput.decimals),
                            ],
                          },
                        ],
                      },
                    );
                    setShowModal("deposit_success");
                    setTokenInput("");
                  } catch (e) {
                    if (!(await isWebWallet())) {
                      setShowModal("error");
                    }
                    setError(formatToReadableError(e, t));
                    throw e;
                  } finally {
                    setRefreshBalance(true);
                    setLoading(false);
                  }
                } else if (chain.chainId === "eth") {
                  if (
                    !allstakeSdk ||
                    !allstakeSdk.ethereum ||
                    !allstakeSdk.ethereum.uiDataProviderContract ||
                    !allstakeSdk.ethereum.strategyManagerContract ||
                    !publicClient ||
                    !ethAccount.address
                  ) {
                    return;
                  }
                  setLoading(true);
                  try {
                    const [userStrategyData, strategyData] = await Promise.all([
                      allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
                        symbolInput.address,
                        ethAccount.address,
                      ),
                      allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
                        symbolInput.address,
                      ),
                    ]);
                    // format input to raw
                    const inputAmount = Amount.parse(
                      tokenInput,
                      symbolInput.decimals,
                    );
                    const strategyTotalBalance = await getErc20Balance(
                      symbolInput.address as Address,
                      userStrategyData.strategy as Address,
                    );
                    // check if click max
                    const isMax = shownBalance === tokenInput;
                    const shares = isMax
                      ? userStrategyData.userShares
                      : balanceToShares(BigInt(inputAmount), {
                          strategyTotalBalance,
                          strategyTotalShares: strategyData.strategyTotalShares,
                        });

                    const strategyAddress =
                      userStrategyData.strategy as Address;
                    if (
                      await checkQueueWithdrawInsufficientAllowance(
                        strategyAddress,
                        ethAccount.address,
                        shares.toString(),
                      )
                    ) {
                      await approve(
                        strategyAddress as Address,
                        strategyAddress as Address,
                        publicClient,
                      );
                    }
                    if (
                      await checkQueueWithdrawInsufficientAllowance(
                        strategyAddress,
                        ethAccount.address,
                        shares.toString(),
                      )
                    ) {
                      setError(t("error.insufficientAllowance"));
                      setShowModal("error");
                    }
                    const txHash =
                      await allstakeSdk.ethereum.strategyManagerContract.queueWithdrawFromStrategy(
                        userStrategyData.strategy,
                        shares,
                      );
                    await waitForTransactionReceiptRecursion(
                      publicClient,
                      txHash,
                    );
                    setShowModal("deposit_success");
                    setTokenInput("");
                    setRefreshBalance(true);
                  } catch (e) {
                    setShowModal("error");
                    setError(formatToReadableError(e, t));
                    throw e;
                  } finally {
                    setLoading(false);
                  }
                }
              },
            },
          ]}
        />
      )}
      {showModal === "addTokenToWalletSuccess" && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={t("error.addTokenToWalletSuccess", {
            token: aToken?.symbol,
          })}
          description=""
          buttons={[
            {
              varient: "primary",
              text: "modal.gotIt",
              onClick: () => setShowModal(undefined),
            },
          ]}
        />
      )}
      {showModal === "addTokenToWalletFailed" && (
        <AllstakeModal
          varient="error"
          onRequestClose={() => setShowModal(undefined)}
          title={t("error.addTokenToWalletFailed", {
            token: aToken?.symbol,
          })}
          description=""
          buttons={[
            {
              varient: "primary",
              text: "modal.gotIt",
              onClick: () => setShowModal(undefined),
            },
          ]}
        />
      )}
      {showModal === "restake_success" && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={`${t("modal.restakeReceived")} ${toLocaleString(tokenInput, 4)} <img class="inline-block h-6 w-6" src="${symbolInput.icon}" /> ${symbolInput.symbol}`}
          description=""
          buttons={[
            {
              varient: "primary",
              text: "modal.continue",
              onClick: () => {
                setShowModal(undefined);
                onClickDeposit();
              },
            },
          ]}
        />
      )}
      {showModal === "deposit_success" && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={`${restakeTab === "deposit" ? "modal.depositSuccess" : t("modal.unstakingHasStarted", { days: UNSTAKE_INTERVAL })}`}
          description=""
          buttonGroupCol
          buttons={
            restakeTab === "deposit" && chain.chainId === "eth"
              ? [
                  {
                    varient: "primary",
                    text: "modal.gotIt",
                    onClick: () => setShowModal(undefined),
                  },
                  {
                    varient: "outline",
                    text: t("modal.addReceiptTokenToWallet", {
                      token: aToken?.symbol,
                    }),
                    onClick: async () => {
                      if (!walletClient) {
                        console.warn("Wallet not connected");
                        return;
                      }

                      if (!aToken) {
                        console.error("aTokenAddress is not fetched");
                        return;
                      }
                      try {
                        const watchAssetResult = await walletClient.watchAsset({
                          type: "ERC20",
                          options: {
                            address: aToken.address,
                            symbol: aToken.symbol,
                            decimals: aToken.decimals,
                          },
                        });

                        if (watchAssetResult) {
                          setShowModal("addTokenToWalletSuccess");
                        } else {
                          setShowModal("addTokenToWalletFailed");
                        }
                      } catch (error) {
                        setShowModal("addTokenToWalletFailed");
                        throw error;
                      }
                    },
                  },
                ]
              : [
                  {
                    varient: "primary",
                    text: "modal.gotIt",
                    onClick: () => setShowModal(undefined),
                  },
                ]
          }
        />
      )}
      {showModal === "error" && (
        <AllstakeModal
          varient="error"
          onRequestClose={() => {
            setShowModal(undefined);
            setLoadingModal(false);
          }}
          title={`${restakeTab === "deposit" ? "modal.depositFailure" : "modal.unstakeFailure"}`}
          description={error}
          buttons={[
            {
              varient: "outline",
              text: "modal.back",
              onClick: () => {
                setShowModal(undefined);
                setLoadingModal(false);
              },
            },
          ]}
        />
      )}
    </RestakingFormContainer>
  );
}

function getDepositButtonText(
  symbolInput: SymbolInput | null,
  isInsufficientBalance: boolean,
  t: TFunction<"translation", undefined>,
) {
  if (!symbolInput) return "";
  if (isInsufficientBalance) {
    return t("restake.insufficientBalance");
  } else if (symbolInput.symbol === "NEAR" || symbolInput.symbol === "SOL") {
    return t("restake.stakeToTokenAndRestake", {
      token: extractToTokenName(symbolInput),
    });
  } else {
    return t("restake.deposit");
  }
}

const ConnectButton = twc.button`
  w-[260px] h-[56px]
  text-base font-semibold
  rounded-full bg-[#4565E5]
`;

const RestakingFormContainer = twc.div`
  bg-[#0C111D] p-10
  flex flex-col gap-8
  min-w-[660px] rounded-xl
`;

const UnstakedText = twc.div`
  text-white text-opacity-40 text-sm font-medium
  flex justify-center mt-[10px]
`;

export default RestakingForm;
