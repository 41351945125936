import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Restake from "./pages/restake";
import Portfolio from "./pages/portfolio";
import Campaign from "./pages/campaign";
import Leaderboard from "./pages/leaderboard";
import { useTranslation } from "react-i18next";

function _Router() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Restake />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="*" element={<Fallback />} />
        </Routes>
      </Layout>
    </Router>
  );
}

const Fallback = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[70vh] flex justify-center items-start pt-20">
      <h1 className="text-5xl font-bold">{t("404.title")}</h1>
    </div>
  );
};

export default _Router;
