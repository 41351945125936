import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { WalletSelector } from "@near-wallet-selector/core";
import { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import "./index.css";
import { WalletSelectorContextProvider } from "./context/WalletSelectorContext.tsx";
import * as Sentry from "@sentry/react";
import "@near-wallet-selector/modal-ui/styles.css";
import { Toaster } from "react-hot-toast";
import { Web3ModalProvider } from "./context/Web3ModalProvider.tsx";
import "react-slideshow-image/dist/styles.css";

if (import.meta.env.VITE_DISABLED_SENTRY !== "true") {
  Sentry.init({
    dsn: "https://cd12acacb066e4913031765c823e038e@o4507382761783296.ingest.us.sentry.io/4507383380574208",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/allstake-ui-pr-.*.onrender\.com/,
      /.*\.*allstake\.org/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// window properties
declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
    allstake_near_wallet_id?: string;
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Web3ModalProvider>
      <WalletSelectorContextProvider>
        <App />
        <Toaster />
      </WalletSelectorContextProvider>
    </Web3ModalProvider>
  </React.StrictMode>,
);
