import { SymbolInput } from "../config/type";
import { CHAIN_CONNECTION_SYMBOL } from "../constants";

export const isInputDigit = (value: string) =>
  /^(\d*(\.\d*)?|\.\d+)$/.test(value);
export const isDigit = (value: string) => /^\d+(\.\d+)?$/.test(value);
export const isInteger = (value: string) => /^\d+$/.test(value);
export const isLetterAndDigit = (value: string) => /^[a-zA-Z0-9]+$/.test(value);
export const removePrefix0 = (value: string) => {
  if (!isDigit(value)) return value;
  if (Number(value) === 0 && !value.includes(".")) return "0";
  else {
    if (value.includes(".")) {
      if (!value.startsWith(".")) {
        return value;
      }
      return value.replace(/^0+/, "0");
    } // 00. transform to 0.
    else return value.replace(/^0+/, ""); // 01 transform to 1
  }
};

export const isMaxDecimals = (_value: string, _decimals: number) => {
  const value = String(_value);
  const decimals = Number(_decimals ?? 0);
  if (!value.includes(".")) {
    return true;
  }
  const splits = value.split(".");
  if (decimals === 0) {
    return false;
  }
  const num = splits[1].length;
  return decimals >= num;
};

export function extractToTokenName(symbolInput: SymbolInput) {
  return extractToTokenNameByName(symbolInput.name) ?? symbolInput.symbol;
}

export function extractToTokenNameByName(name: string) {
  if (name.includes(CHAIN_CONNECTION_SYMBOL)) {
    return name.split(CHAIN_CONNECTION_SYMBOL)[1];
  }
  return name;
}

export const isChainNativeToken = (tokenName: string) =>
  tokenName.includes(CHAIN_CONNECTION_SYMBOL);

export const formatTokenNameInput = (tokenName: string) =>
  tokenName.replace(CHAIN_CONNECTION_SYMBOL, " ➟ ");
