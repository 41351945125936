import toast from "react-hot-toast";
import { CopyImg } from "../../assets";
import {
  ContentContainer,
  ContentTitle,
  ContentItem,
  Opacity80,
  GemImage,
  GemColor,
  StepContainer,
} from "./components";
import { useMemo, useState } from "react";
import { GemUser } from "../../utils/twitterApiInstance";
import { useTranslation } from "react-i18next";

function Step4({ gemUser }: { gemUser: GemUser | null }) {
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const inviteCode = gemUser?.referral_code ?? "-";
  const urlAddress = useMemo(() => {
    const urlAddress = new URL(window.location.href);
    urlAddress.search = "";
    urlAddress.searchParams.set("inviteCode", inviteCode);
    return urlAddress.toString();
  }, [inviteCode, window.location.href]);
  async function copyToClipboard() {
    await navigator.clipboard.writeText(urlAddress.toString());
    if (copiedId == null) {
      const id = await toast.success(t("toast.copiedToClipboard"));
      setCopiedId(id);
    } else {
      await toast.success(t("toast.copiedToClipboard"), {
        id: copiedId,
      });
    }
  }
  const { t } = useTranslation();
  return (
    <StepContainer>
      <ContentContainer>
        <ContentTitle>
          {t("campaign.congratulations")}{" "}
          {gemUser?.twitter_username && (
            <a
              href={`https://x.com/${gemUser.twitter_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GemColor className="text-base">
                @{gemUser.twitter_username}
              </GemColor>
            </a>
          )}
        </ContentTitle>

        <ContentItem>
          <Opacity80>{t("campaign.youHave")}</Opacity80>
          <GemImage className="mx-1" />
          <Opacity80>
            {gemUser?.gem_amount ?? "-"} {t("campaign.allstakeGemsToClaim")}
          </Opacity80>
        </ContentItem>
        <ContentItem>
          <Opacity80>{t("campaign.referYourFriendsNow")}</Opacity80>
        </ContentItem>
        <ContentItem>
          <Opacity80>{t("campaign.earn")}</Opacity80>
          <GemImage className="mx-1" />
          <Opacity80>{t("campaign.plus200Gems")}</Opacity80>
        </ContentItem>
        <ContentItem>
          <GemColor>
            {t("campaign.yourInviteCode")}:{" "}
            <a
              className="border-b border-[#4578f6]"
              href={urlAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {inviteCode}
            </a>
            <button onClick={copyToClipboard}>
              <img className="inline-block ml-1" src={CopyImg} />
            </button>
          </GemColor>
        </ContentItem>
      </ContentContainer>
    </StepContainer>
  );
}

export default Step4;
