import { useState } from "react";
import { createContainer } from "react-tracked";
import { SymbolInput } from "../config/type";

const useSymbolInput = () => useState<SymbolInput | null>(null);

const { Provider: SymbolInputProvider, useTracked: useSymbolInputTracked } =
  createContainer(useSymbolInput);

export default {
  useSymbolInputTracked,
  SymbolInputProvider,
};
