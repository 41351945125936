import Big from "big.js";
import { AllstakeLogoImg } from "../assets";
import { ChainId } from "../hooks/useChain";

export const TGas = Big(10).pow(12);
export const MIN_SHOWN_NUMBER = 0.0001;
export const WALLET_CONNECT_METADATA = {
  name: "Allstake",
  description: "Omnichain Restaking, Trustless Scaling.",
  url: window.location.protocol + "//" + window.location.host,
  icons: [AllstakeLogoImg],
};
export const NEAR_BALANCE_GAS_RESERVATION = 0.05;
export const SOL_BALANCE_GAS_RESERVATION = 0.05;
export const UNSTAKE_INTERVAL = 2;
export const ICON_PAGE_SIZE = 9;
export const TVL_MIN_PRECISION = 0.01;

export const AUDIT_LINK = "https://docs.allstake.org/security/audits";

export type Gem_Chain_Id_Type = "NEAR" | "Solana" | "Ethereum" | "Bitcoin";

export const GEM_CHAINID_MAPPING: Record<ChainId, Gem_Chain_Id_Type> = {
  near: "NEAR",
  solana: "Solana",
  eth: "Ethereum",
  btc: "Bitcoin",
};

type ReverseGemChainIdMapping = {
  [K in keyof typeof GEM_CHAINID_MAPPING as (typeof GEM_CHAINID_MAPPING)[K]]: K;
};

export const reverseGemChainIdMapping: ReverseGemChainIdMapping =
  Object.fromEntries(
    Object.entries(GEM_CHAINID_MAPPING).map(([key, value]) => [value, key]),
  ) as ReverseGemChainIdMapping;

export const DROPDOWN_NETWORKS = ["eth", "near", "solana", "btc"];

export const apiChainMapping: Record<Exclude<ChainId, undefined>, string> = {
  solana: "solana",
  near: "near",
  btc: "btc",
  eth: "ethereum",
};

type ReverseApiChainMapping = {
  [K in keyof typeof apiChainMapping as (typeof apiChainMapping)[K]]: K;
};

export const reverseApiChainMapping: ReverseApiChainMapping =
  Object.fromEntries(
    Object.entries(apiChainMapping).map(([key, value]) => [value, key]),
  ) as ReverseApiChainMapping;

export const CHAIN_CONNECTION_SYMBOL = "~";

export const localStorageKey = {
  twitter_code: "twitter_code",
  twitter_state: "twitter_state",
  allstake_campaign_invite_code: "allstake_campaign_invite_code",
  campaign_chain_query: "campaign_chain_query",
};

export const WAGMI_STORAGE_KEY = "ALLSTAKE_WAGMI_STORAGE";

export const LEADERBOARD_ITEM_WIDTH = [
  "w-[5%]",
  "w-[40%]",
  "w-[40%]",
  "w-[15%]",
];
