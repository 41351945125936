import { ChainId } from "../hooks/useChain";

export function setNearSignMessageCallbackUrl(callbackUrl: string) {
  return localStorage.setItem("NEAR_SIGN_MESSAGE_CALLBACK_URL", callbackUrl);
}

export function getNearMessageCallbackUrl() {
  return localStorage.getItem("NEAR_SIGN_MESSAGE_CALLBACK_URL");
}

export function getTwitterKeyFromLocalStorage(
  key: string,
  chainId: ChainId,
  address: string,
) {
  console.log({
    key: `${key}:${chainId}:${address}`,
  });
  return localStorage.getItem(`${key}:${chainId}:${address}`);
}

export function setTwitterDataToLocalStorage(
  key: string,
  chainId: ChainId,
  address: string,
  data: string,
) {
  return localStorage.setItem(`${key}:${chainId}:${address}`, data);
}

export function remoteTwitterKeyFromLocalStorage(
  key: string,
  chainId: ChainId,
  address: string,
) {
  return localStorage.removeItem(`${key}:${chainId}:${address}`);
}
