import { twc } from "react-twc";
import { GemImage } from "../campaign/components";
import { toShortAddress } from "../../utils/address";
import {
  BtcImg,
  CopyImg,
  EthImg,
  NearImg,
  SolanaImg,
  XImg,
} from "../../assets";
import toast from "react-hot-toast";
import { useState } from "react";
import {
  LEADERBOARD_ITEM_WIDTH,
  localStorageKey,
  reverseGemChainIdMapping,
} from "../../constants";
import useChain, { ChainId } from "../../hooks/useChain";
import { getTwitterKeyFromLocalStorage } from "../../utils/localStorage";
import useSWR from "swr";
import {
  getLeaderboardDataByState,
  LeaderBoardUser,
} from "../../utils/twitterApiInstance";
import toLocaleString from "../../utils/toLocaleString";
import { isDigit } from "../../utils/regex";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

function Leaderboard() {
  const chain = useChain();
  const { data: leaderboardData } = useSWR(
    `getLeaderboardDataByState-${chain?.chainId}-${chain?.address}`,
    async () => {
      const twitterState =
        chain && chain.chainId && chain.address
          ? getTwitterKeyFromLocalStorage(
              localStorageKey.twitter_state,
              chain.chainId,
              chain.address,
            ) ?? undefined
          : undefined;
      return getLeaderboardDataByState({ state: twitterState });
    },
  );
  const { t } = useTranslation();
  return (
    <div className="px-4">
      <LeaderboardStyle>
        <TopInfoContent>
          <div className="flex flex-col gap-2">
            <p className="text-[28px] font-semibold">
              {t("leaderboard.title")}
            </p>
            <p className="text-sm">{t("leaderboard.description")}</p>
          </div>
          <div>
            <TotalParticipantsButton
              totalParticipants={leaderboardData?.data.totalUsersNum}
            />
          </div>
        </TopInfoContent>
        <Divider />
        <LeaderboardTable
          users={leaderboardData?.data.users}
          user={leaderboardData?.data.user}
        />
      </LeaderboardStyle>
    </div>
  );
}

const LeaderboardTable = ({
  user,
  users,
}: {
  user?: LeaderBoardUser;
  users?: LeaderBoardUser[];
}) => {
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const leaderboardData = users ?? [];

  const getChainImage = (chainId: ChainId) => {
    switch (chainId) {
      case "solana":
        return SolanaImg;
      case "eth":
        return EthImg;
      case "btc":
        return BtcImg;
      case "near":
        return NearImg;
    }
  };
  const { t } = useTranslation();
  return (
    <div className="px-10 py-6 flex flex-col flex-1">
      <LeaderboardRow className="p-3">
        <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[0]}>
          {t("leaderboard.rank")}
        </LeaderboardItem>
        <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[1]}>
          {t("leaderboard.wallet")}
        </LeaderboardItem>
        <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[2]}>
          <img src={XImg} />
          {t("leaderboard.username")}
        </LeaderboardItem>
        <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[3]}>
          {t("leaderboard.totalGems")}
        </LeaderboardItem>
      </LeaderboardRow>
      {user && (
        <LeaderboardRowContent className="p-3 mb-3 mt-[6px]">
          <TopLeaderboardItem className={LEADERBOARD_ITEM_WIDTH[0]}>
            {user.rank}
          </TopLeaderboardItem>
          <TopLeaderboardItem className={LEADERBOARD_ITEM_WIDTH[1]}>
            {reverseGemChainIdMapping[user.chain] && (
              <img
                className="w-6 h-6"
                src={getChainImage(reverseGemChainIdMapping[user.chain])}
              />
            )}
            <p title={user.address}>{toShortAddress(user.address)}</p>
            <button
              onClick={async () => {
                await navigator.clipboard.writeText(user.address);
                if (copiedId == null) {
                  const id = await toast.success(t("toast.copiedToClipboard"));
                  setCopiedId(id);
                } else {
                  await toast.success(t("toast.copiedToClipboard"), {
                    id: copiedId,
                  });
                }
              }}
            >
              <img className="w-6 h-6" src={CopyImg} />
            </button>
          </TopLeaderboardItem>
          <TopLeaderboardItem className={LEADERBOARD_ITEM_WIDTH[2]}>
            <a
              className="hover:underline"
              href={`https://x.com/${user.twitter_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              @{user.twitter_username}
            </a>
          </TopLeaderboardItem>
          <TopLeaderboardItem className={LEADERBOARD_ITEM_WIDTH[3]}>
            <GemImage />
            <p>{toLocaleString(user.gem_amount)}</p>
          </TopLeaderboardItem>
        </LeaderboardRowContent>
      )}
      <div
        className={clsx(
          "flex flex-col gap-3 overflow-auto hide-scrollbar",
          user ? "h-[320px]" : "h-[400px]",
        )}
      >
        {leaderboardData.map((item, index) => (
          <LeaderboardRow
            key={index}
            className="border border-[#FFFFFF24] rounded-lg p-3"
          >
            <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[0]}>
              {item.rank}
            </LeaderboardItem>
            <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[1]}>
              {reverseGemChainIdMapping[item.chain] && (
                <img
                  className="w-6 h-6"
                  src={getChainImage(reverseGemChainIdMapping[item.chain])}
                />
              )}
              <p title={item.address}>{toShortAddress(item.address)}</p>
              <button
                onClick={async () => {
                  await navigator.clipboard.writeText(item.address);
                  if (copiedId == null) {
                    const id = await toast.success(
                      t("toast.copiedToClipboard"),
                    );
                    setCopiedId(id);
                  } else {
                    await toast.success(t("toast.copiedToClipboard"), {
                      id: copiedId,
                    });
                  }
                }}
              >
                <img className="w-6 h-6" src={CopyImg} />
              </button>
            </LeaderboardItem>
            <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[2]}>
              <a
                className="hover:underline"
                href={`https://x.com/${item.twitter_username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{item.twitter_username}
              </a>
            </LeaderboardItem>
            <LeaderboardItem className={LEADERBOARD_ITEM_WIDTH[3]}>
              <GemImage />
              <p>{toLocaleString(item.gem_amount)}</p>
            </LeaderboardItem>
          </LeaderboardRow>
        ))}
      </div>
    </div>
  );
};

const LeaderboardRowContent = twc.div`
  bg-white/5
  backdrop-blur-sm
  border border-[#29AFF6]
  rounded-lg
  px-10
  flex
  items-center
`;

const TopLeaderboardItem = twc.div`
  text-[#5BC6FF] font-semibold
  flex items-center justify-center gap-2
`;
const LeaderboardItem = twc.div`
  text-white/80
  flex items-center justify-center gap-2
`;

const LeaderboardRow = twc.div`
  px-10 flex items-center 
`;

const Divider = () => {
  return <div className="h-[1px] w-full bg-white bg-opacity-15" />;
};

const TotalParticipantsButton = ({
  totalParticipants,
}: {
  totalParticipants?: string;
}) => {
  const { t } = useTranslation();
  return (
    <TotalParticipantsButtonStyle
      style={{
        background:
          "linear-gradient(89.87deg, #29AFF7 -20.66%, #9B19FF 104.96%)",
      }}
    >
      <p>{t("leaderboard.totalParticipants")}</p>
      <p>
        {totalParticipants && isDigit(totalParticipants)
          ? toLocaleString(totalParticipants)
          : "-"}
      </p>
    </TotalParticipantsButtonStyle>
  );
};

const TotalParticipantsButtonStyle = twc.button`
  flex gap-1 justify-center items-center
  rounded-full px-5 py-2
  text-lg font-semibold cursor-default
`;

const TopInfoContent = twc.div`
  px-10 pt-10 pb-6
  flex justify-between items-center
`;
const LeaderboardStyle = twc.div`
  w-full xl:w-[1000px] m-auto mt-10 h-[80vh]
  bg-[#0C111D59] rounded-2xl
   backdrop-blur-sm flex flex-col
`;

export default Leaderboard;
