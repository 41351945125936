import { useTranslation } from "react-i18next";
import {
  getAuthorizeUrl,
  getUserByChainAddress,
} from "../../utils/twitterApiInstance";
import {
  ContentContainer,
  ContentTitle,
  XImage,
  ContentItem,
  Opacity80,
  GemImage,
  GemColor,
  StepContainer,
  AllStakeFi,
  PrimaryButton,
} from "./components";
import { useAccount, useWalletClient } from "wagmi";
import { resetWagmiState } from "../../utils/wagmi";
import useSWR from "swr";
import useChain from "../../hooks/useChain";
import { GEM_CHAINID_MAPPING } from "../../constants";

function Step2() {
  const { t } = useTranslation();
  const { data: walletClient } = useWalletClient();
  const ethAccount = useAccount();
  const chain = useChain();
  const { data: user } = useSWR(
    `getUserByChainAddress_${chain?.chainId}_${chain?.address}`,
    async () => {
      if (!chain || !chain.chainId || !chain.address) return undefined;
      return await getUserByChainAddress(
        GEM_CHAINID_MAPPING[chain.chainId],
        chain.address,
      );
    },
  );
  return (
    <StepContainer>
      <ContentContainer>
        <ContentTitle>
          {t("campaign.connect")} <XImage className="ml-1" />
        </ContentTitle>
        {user ? (
          <ContentItem>
            <Opacity80>
              {t("campaign.youHaveAlreadyConnectedYourWallet")}{" "}
              <a
                className="hover:underline"
                href={`https://x.com/${user.twitter_username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{user.twitter_username}
              </a>
              .{" "}
            </Opacity80>
            <Opacity80>
              {t("campaign.toClaimYour")}
              <GemImage className="mx-1" />
              <GemColor>{t("campaign.gemsReward")}</GemColor>.{" "}
              <a
                className="hover:underline"
                href={`https://x.com/@allstake_fi`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @allstake_fi
              </a>{" "}
              {t("campaign.toUnlockYourGems")}
            </Opacity80>
          </ContentItem>
        ) : (
          <ContentItem>
            <Opacity80>{t("campaign.toClaimYour")}</Opacity80>
            <GemImage className="mx-1" />
            <GemColor>{t("campaign.gemsReward")}</GemColor>
            <Opacity80>{t("campaign.makeSureToConnectYour")}</Opacity80>
            <XImage className="mx-1" />
            <Opacity80>{t("campaign.andFollowOurOfficial")}</Opacity80>
            <XImage className="mx-1" />
            <Opacity80>{t("campaign.account")}</Opacity80>
            <AllStakeFi className="mx-1" />
            <Opacity80>{t("campaign.toUnlockYourGems")}</Opacity80>
          </ContentItem>
        )}
      </ContentContainer>
      <div className="items-end justify-end">
        <PrimaryButton
          onClick={async () => {
            // check wagmi state first
            if (!walletClient && ethAccount.isConnected) {
              // reset wagmi state
              resetWagmiState();
              return;
            }
            const authorizeUrl = await getAuthorizeUrl();
            if (authorizeUrl) {
              window.location.href = authorizeUrl;
            }
          }}
        >
          {t("campaign.connect")} <XImage className="ml-1" />
        </PrimaryButton>
      </div>
    </StepContainer>
  );
}

export default Step2;
