import { twc } from "react-twc";
import { GemImg, XImg } from "../../assets";
import clsx from "clsx";

export const StepContainer = twc.div`
  gap-10 md:gap-4
  flex-col md:flex-row
  flex md:justify-between md:items-end
`;

export const GemImage = ({ className }: { className?: string }) => (
  <img className={clsx("inline-block", className)} src={GemImg} />
);
export const XImage = ({ className }: { className?: string }) => (
  <img className={clsx("inline-block", className)} src={XImg} />
);

export const AllStakeFi = ({ className }: { className?: string }) => (
  <span className={clsx("font-bold", className)}>@allstake_fi</span>
);
export const GemColor = twc.span`
  gem-linear bg-clip-text text-transparent font-bold
  inline-block
`;

export const Opacity80 = twc.span`
  opacity-80 
`;
export const ContentItem = twc.div`
  
`;

export const ContentTitle = twc.div`
  text-[22px] font-semibold
`;

export const ContentContainer = twc.div`
  flex flex-col gap-6
`;

export const PrimaryButton = twc.button`
  w-[200px] h-[48px]
  text-base font-semibold
  rounded-full bg-[#4565E5]
  flex justify-center items-center
  whitespace-nowrap disabled:opacity-60 disabled:cursor-not-allowed
`;
