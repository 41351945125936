import { PropsWithChildren } from "react";
import Header from "./header";
import Footer from "./footer";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SolongWalletAdapter } from "@solana/wallet-adapter-solong";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { LedgerWalletAdapter } from "@solana/wallet-adapter-ledger";
import { WalletConnectWalletAdapter } from "@solana/wallet-adapter-walletconnect";

import { clusterApiUrl } from "@solana/web3.js";
import GlobalStorageContext from "../../context";
import { BlueGradientFullScreenBgImg, TopGradientBgImg } from "../../assets";
import { useLocation } from "react-router-dom";
import Audit from "./Audit";

const TopGradientImage = () => (
  <img
    className="absolute top-0 left-0 w-full h-[380px] object-cover -z-10"
    src={TopGradientBgImg}
  />
);

const BlueGradientFullScreenBgImage = () => (
  <img
    className="fixed top-0 left-0 w-full h-screen object-cover -z-10"
    src={BlueGradientFullScreenBgImg}
  />
);

function Layout({ children }: PropsWithChildren) {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = clusterApiUrl(network);
  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new SolongWalletAdapter(),
    new WalletConnectWalletAdapter({
      network,
      options: {},
    }),
    // package json overrides: https://github.com/anza-xyz/wallet-adapter/pull/949
    new LedgerWalletAdapter(),
  ];
  const { pathname } = useLocation();
  const isBlueGradientFullScreenBg =
    pathname.includes("campaign") || pathname.includes("leaderboard");
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <GlobalStorageContext>
            {isBlueGradientFullScreenBg && <BlueGradientFullScreenBgImage />}
            {!isBlueGradientFullScreenBg && <TopGradientImage />}
            <Header />
            {children}
            <Audit />
            <Footer />
          </GlobalStorageContext>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default Layout;
