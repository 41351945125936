import { twc } from "react-twc";
import { ArrowDownImg, BtcImg, EthImg, NearImg, SolanaImg } from "../../assets";
import clsx from "clsx";
import SymbolInputContext from "../../context/SymbolInputContext";
import SelectedNetworkContext from "../../context/SelectedNetwork";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { Chain, ChainId } from "../../hooks/useChain";
import ImageWithLoader from "../image-with-loader";

const { useSymbolInputTracked } = SymbolInputContext;
const { useSelectedNetworkTracked } = SelectedNetworkContext;
function NetworkSwitcher({ chain }: { chain: Exclude<Chain, null> }) {
  const [isOpen, setIsOpen] = useState(false);
  const [, setSymbolInput] = useSymbolInputTracked();
  const navigate = useNavigate();
  const location = useLocation();

  const [, setSelectedNetwork] = useSelectedNetworkTracked();

  const onClickDropdownItem = (chainId: ChainId) => {
    setIsOpen(false);
    if (!chainId || !config.supportNetworks.includes(chainId)) {
      return;
    }

    // Don't need to switch network when it's already on the current network
    if (chainId === chain?.chainId) {
      return;
    }

    const token =
      config.restakingTokens.find((token) => token.chain === chainId) ??
      config.restakingTokens[0];

    setSymbolInput(token);
    setSelectedNetwork([token.chain]);
    navigate(`${location.pathname}?chain=${token.chain}`);
  };

  const networkItemClassName = (chainId: string) =>
    clsx(
      chain?.chainId === chainId && "bg-[#1F242F]",
      !config.supportNetworks.includes(chainId) &&
        "opacity-50 !cursor-not-allowed",
    );

  const getChainImage = (chainId?: ChainId) => {
    switch (chainId) {
      case "solana":
        return SolanaImg;
      case "eth":
        return EthImg;
      case "near":
        return NearImg;
      case "btc":
        return BtcImg;
    }
  };

  const dropdownItems: Array<{
    name: string;
    component: JSX.Element;
  }> = [
    {
      name: "eth",
      component: (
        <DropdownItem
          key="eth"
          className={networkItemClassName("eth")}
          onClick={() => {
            onClickDropdownItem("eth");
          }}
        >
          <div className="flex gap-2">
            <img
              className="w-[22px] h-[22px]"
              src={EthImg}
              width={20}
              height={20}
            />
            <p>ETH</p>
          </div>
          {chain?.chainId === "eth" ? <OpenCircle /> : <CloseCircle />}
        </DropdownItem>
      ),
    },
    {
      name: "near",
      component: (
        <DropdownItem
          key="near"
          className={networkItemClassName("near")}
          onClick={() => onClickDropdownItem("near")}
        >
          <div className="flex gap-2">
            <img
              className="w-[22px] h-[22px]"
              src={NearImg}
              width={20}
              height={20}
            />
            <p>NEAR</p>
          </div>
          {chain?.chainId === "near" ? <OpenCircle /> : <CloseCircle />}
        </DropdownItem>
      ),
    },
    {
      name: "solana",
      component: (
        <DropdownItem
          key="solana"
          className={networkItemClassName("solana")}
          onClick={() => onClickDropdownItem("solana")}
        >
          <div className="flex gap-2">
            <img
              className="w-[22px] h-[22px]"
              src={SolanaImg}
              width={20}
              height={20}
            />
            <p>SOLANA</p>
          </div>
          {chain?.chainId === "solana" ? <OpenCircle /> : <CloseCircle />}
        </DropdownItem>
      ),
    },
    {
      name: "btc",
      component: (
        <DropdownItem
          key="btc"
          className={networkItemClassName("btc")}
          onClick={() => {}}
        >
          <div className="flex gap-2">
            <img
              className="w-[22px] h-[22px]"
              src={BtcImg}
              width={20}
              height={20}
            />
            <p>BTC</p>
          </div>
          {chain?.chainId === "btc" ? <OpenCircle /> : <CloseCircle />}
        </DropdownItem>
      ),
    },
  ].sort((a, b) => {
    const networks = config.supportNetworks;
    if (
      (networks.includes(a.name) && networks.includes(b.name)) ||
      (!networks.includes(a.name) && !networks.includes(b.name))
    ) {
      return 0;
    } else if (networks.includes(a.name)) {
      return -1;
    } else {
      return 1;
    }
  });
  return (
    <NetworkSwitcherStyle>
      <DropdownMenu.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DropdownMenu.Trigger className="outline-none">
          <SelectedNetwork className={clsx(isOpen && "token-input-shadow")}>
            <div className="flex items-center gap-3">
              <ImageWithLoader
                className="w-7 h-7"
                src={getChainImage(chain.chainId)}
                width={28}
                height={28}
              />
              <p>{(chain.chainId ?? "").toUpperCase()}</p>
            </div>
            <img src={ArrowDownImg} width={20} height={20} />
          </SelectedNetwork>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="w-full" sideOffset={20}>
            <DropdownContainer>
              {dropdownItems.map((dropdownItem) => dropdownItem.component)}
            </DropdownContainer>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </NetworkSwitcherStyle>
  );
}

const OpenCircle = () => (
  <div className="rounded-full flex w-[10px] h-[10px] bg-[#22FF53] border-2 bg-clip-padding border-white border-opacity-20" />
);
const CloseCircle = () => (
  <div className="rounded-full flex w-[10px] h-[10px] bg-white bg-opacity-20 border-2 border-[#0b0e16]" />
);

const DropdownItem = twc.div`
  flex justify-between items-center gap-2 py-3 px-2
  hover:bg-[#1F242F]
  rounded-md cursor-pointer
`;

const DropdownContainer = twc.div`
  w-full min-w-[160px] outline-none
  rounded-md
  bg-[#0C111D]
  border border-[#1F242F]
  p-2
  flex flex-col gap-2
`;

const NetworkSwitcherStyle = twc.div`
  relative
`;

const SelectedNetwork = twc.div`
  py-2 px-3 h-[44px] min-w-[160px]
  rounded-full
  bg-white bg-opacity-5
  cursor-pointer select-none
  flex justify-between items-center gap-3
  font-semibold
`;

export default NetworkSwitcher;
