import { useMemo } from "react";
import SymbolInputContext from "../context/SymbolInputContext";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAccount, useWalletClient } from "wagmi";

export type ChainId = "solana" | "near" | "btc" | "eth";

const { useSymbolInputTracked } = SymbolInputContext;

export type Chain = {
  chainId?: ChainId;
  isSignedIn: boolean;
  isDirectConnected: boolean;
  address: string | null;
} | null;
function useChain() {
  const [symbolInput] = useSymbolInputTracked();
  const { selector, accountId } = useWalletSelector();
  const solanaWallet = useWallet();
  const ethAccount = useAccount();
  const { data: walletClient } = useWalletClient();

  const chain: Chain = useMemo(() => {
    if (!symbolInput) return null;
    if (symbolInput.chain === "near") {
      return {
        chainId: symbolInput.chain,
        isSignedIn: selector.isSignedIn(),
        isDirectConnected: selector.isSignedIn(),
        address: accountId,
      };
    } else if (symbolInput.chain === "solana") {
      if (solanaWallet.connecting) return null;
      return {
        chainId: symbolInput.chain,
        isSignedIn: solanaWallet.connected,
        isDirectConnected: solanaWallet.connected,
        address: solanaWallet.publicKey?.toBase58() ?? null,
      };
    } else if (symbolInput.chain === "eth") {
      const isSignedIn =
        !ethAccount.isConnecting && ethAccount.isConnected && !!walletClient;
      const address = isSignedIn ? (ethAccount.address as string) : null;
      return {
        chainId: symbolInput.chain,
        isSignedIn,
        isDirectConnected: ethAccount.isConnected,
        address,
      };
    } else {
      return null;
    }
  }, [symbolInput, selector.isSignedIn(), accountId, solanaWallet, ethAccount]);
  return chain;
}

export default useChain;
