import { keyStores } from "near-api-js";
import { Config } from "./type";
import {
  AuroraImg,
  JitoSolImg,
  LinearImg,
  MsolImg,
  NearImg,
  SolanaImg,
  BSolImg,
  InfImg,
  StNearImg,
  FxsImg,
  SfrxImg,
  FraxImg,
  SolvBTCImg,
  SolvBTCBabylonImg,
  USDeImg,
  SUSDeImg,
} from "../assets";

const config: Config = {
  near: {
    networkId: "mainnet",
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: "https://near.lava.build",
    walletUrl: "https://app.mynearwallet.com",
    helperUrl: "https://helper.near.org",
    headers: {},
  },
  walletConnectProjectId: "b88c48420e260b9323017e1a43677f11",
  allstakeSdkOptions: {
    near: {
      networkId: "mainnet",
      contracts: {
        strategyManagerContractId: "allstake-staging.near",
      },
    },
    solana: {
      networkId: "mainnet-beta",
      programs: {
        strategyManagerProgramId: "stgKLYZXs3zNWM7NKpTdUdCbcfsLnML3vaWq8i8KxUS",
      },
    },
    ethereum: {
      networkId: "mainnet",
      contracts: {
        uiDataProviderContractId: "0x2A3b2e07512e602912092F560a9C16Ea5Cf9560E",
        strategyManagerContractId: "0x3e189CC88b9E41De11D532481a820db6010f5DA7",
      },
      pendle: {
        oracleAddress: "0x9a9Fa8338dd5E5B2188006f1Cd2Ef26d921650C2",
        ptTokenInfos: {
          "PT-weETH": {
            marketAddress: "0x7d372819240d14fb477f17b964f95f33beb4c704",
            underlyingTokenAddress:
              "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
          },
        },
      },
    },
  },
  pyth: {
    apiUrl: "https://hermes.pyth.network/v2",
    priceFeedIds: {
      // chain token
      NEAR: "c415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750", // NEAR
      SOL: "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d", // SOL

      // near
      "aaaaaa20d9e0e2461697782ef11675f668207961.factory.bridge.near":
        "0x2f7c4f738d498585065a4b87b637069ec99474597da7f0ca349ba8ac3ba9cac5", // aurora

      // sol
      mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So:
        "c2289a6a43d2ce91c6f55caec370f4acc38a2ed477f58813334c6d03749ff2a4", // MSOL
      J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn:
        "67be9f519b95cf24338801051f9a808eff0a578ccb388db73b7f6fe1de019ffb", // JITOSOL
      bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1:
        "0x89875379e70f8fbadc17aef315adf3a8d5d160b811435537e03c97e8aac97d9c", // bSOL
      "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm":
        "0xf51570985c642c49c2d6e50156390fdba80bb6d5f7fa389d2f012ced4f7d208f", // INF

      // eth
      "0x4c9edd5852cd905f086c759e8383e09bff1e68b3":
        "0x6ec879b1e9963de5ee97e9c8710b742d6228252a5e2ca12d4ae81d7fe5ee8c5d", // USDE
      "0x9d39a5de30e57443bff2a8307a4256c8797a3497":
        "0xca3ba9a619a4b3755c10ac7d5e760275aa95e9823d38a84fedd416856cdba37c", // sUSDE
      "0x7a56e1c57c7475ccf742a1832b028f0456652f97":
        "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43", // SolvBTC (BTC)
      "0xd9d920aa40f578ab794426f5c90f6c731d159def":
        "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43", // SolvBTC.BBN (BTC)
      "0xac3E018457B222d93114458476f3E3416Abbe38F":
        "0xb2bb466ff5386a63c18aa7c3bc953cb540c755e2aa99dafb13bc4c177692bed0", // SFRXETH
      "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0":
        "0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1", // FXS
      "0x853d955aCEf822Db058eb8505911ED77F175b99e":
        "0xc3d5d8d6d17081b3d0bbca6e2fa3a6704bb9a9561d9f9e1dc52db47629f862ad", // FRAX
      "0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee":
        "0x9ee4e7c60b940440a261eb54b6d8149c23b580ed7da3139f7f08f4ea29dad395", // PT-weETH
      "0x35fA164735182de50811E8e2E824cFb9B6118ac2":
        "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace", // EETH (ETH)
      "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee":
        "0x9ee4e7c60b940440a261eb54b6d8149c23b580ed7da3139f7f08f4ea29dad395", // WEETH
      "0xbf5495Efe5DB9ce00f80364C8B423567e58d2110":
        "0x06c217a791f5c4f988b36629af4cb88fad827b2485400a358f3b02886b54de92", // EZETH
      "0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7":
        "0x0caec284d34d836ca325cf7b3256c078c597bc052fbd3c0283d52b581d68d71f", // RSETH
      "0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0":
        "0x17e349391a4d8362706ec4126c2fa42047601cb71c1063e38ca305fab9b0ec4d", // RSWETH
    },
  },
  coingecko: {
    apiUrl: "https://api.coingecko.com/api/v3",
    ids: {
      NEAR: "near",
      SOL: "solana",
      mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So: "msol",
      J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn: "jito-staked-sol",
    },
  },
  restakingTokens: [
    {
      chain: "eth",
      icon: USDeImg,
      symbol: "USDe",
      name: "USDe",
      decimals: 18,
      address: "0x4c9edd5852cd905f086c759e8383e09bff1e68b3",
      minAmount: 0.01,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.ethena.fi/buy",
      selectTag: ["eth", "Stablecoin"],
      boostTag: ["4x Allstake points", "20x Ethena sats"],
    },
    {
      chain: "eth",
      icon: SUSDeImg,
      symbol: "sUSDe",
      name: "sUSDe",
      decimals: 18,
      address: "0x9d39a5de30e57443bff2a8307a4256c8797a3497",
      minAmount: 0.01,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.ethena.fi",
      selectTag: ["eth", "Stablecoin"],
      boostTag: ["3x Allstake points", "5x Ethena sats"],
    },
    {
      chain: "eth",
      icon: SolvBTCImg,
      symbol: "SolvBTC",
      name: "SolvBTC",
      decimals: 18,
      address: "0x7a56e1c57c7475ccf742a1832b028f0456652f97",
      minAmount: 0.00001,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.solv.finance/solvbtc",
      selectTag: ["eth", "LST"],
      boostTag: ["2x Allstake points", "2x Solv points"],
    },
    {
      chain: "eth",
      icon: SolvBTCBabylonImg,
      symbol: "SolvBTC.BBN",
      name: "SolvBTC.BBN",
      decimals: 18,
      address: "0xd9d920aa40f578ab794426f5c90f6c731d159def",
      minAmount: 0.00001,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.solv.finance/babylon",
      selectTag: ["eth", "LST"],
      boostTag: ["2x Allstake points", "12x Solv points"],
    },
    {
      chain: "eth",
      icon: SfrxImg,
      symbol: "sfrxETH",
      name: "sfrxETH",
      decimals: 18,
      address: "0xac3E018457B222d93114458476f3E3416Abbe38F",
      minAmount: 0.0001,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.frax.finance/frxeth/stake",
      selectTag: ["eth", "LST"],
      boostTag: [],
    },
    {
      chain: "eth",
      icon: FraxImg,
      symbol: "FRAX",
      name: "FRAX",
      decimals: 18,
      address: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
      minAmount: 0.1,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.frax.finance/swap/main?from=native&to=0x853d955aCEf822Db058eb8505911ED77F175b99e",
      selectTag: ["eth", "Stablecoin"],
      boostTag: [],
    },
    {
      chain: "eth",
      icon: FxsImg,
      symbol: "FXS",
      name: "FXS",
      decimals: 18,
      address: "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
      minAmount: 0.1,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      url: "https://app.frax.finance/swap/main?from=native&to=0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
      selectTag: ["eth", "Governance"],
      boostTag: [],
    },
    // {
    //   chain: "eth",
    //   icon: PtEthImg,
    //   symbol: "PT-weETH",
    //   name: "PT-weETH",
    //   decimals: 18,
    //   address: "0x6ee2b5e19ecba773a352e5b21415dc419a700d1d",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://app.pendle.finance/trade/markets/0x7d372819240d14fb477f17b964f95f33beb4c704/swap?view=pt&chain=ethereum&py=output&chart=info",
    //   selectTag: ["eth", "Pendle"],
    //   boostTag: [],
    // },
    // {
    //   chain: "eth",
    //   icon: EethImg,
    //   symbol: "eETH",
    //   name: "eETH",
    //   decimals: 18,
    //   address: "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://app.ether.fi/eeth",
    //   selectTag: ["eth", "LRT"],
    //   boostTag: [],
    // },
    // {
    //   chain: "eth",
    //   icon: WeethImg,
    //   symbol: "weETH",
    //   name: "weETH",
    //   decimals: 18,
    //   address: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://app.ether.fi/eeth/wrap",
    //   selectTag: ["eth", "LRT"],
    //   boostTag: [],
    // },
    // {
    //   chain: "eth",
    //   icon: EzethImg,
    //   symbol: "ezETH",
    //   name: "ezETH",
    //   decimals: 18,
    //   address: "0xbf5495Efe5DB9ce00f80364C8B423567e58d2110",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://app.renzoprotocol.com/restake",
    //   selectTag: ["eth", "LRT"],
    //   boostTag: [],
    // },
    // {
    //   chain: "eth",
    //   icon: RsethImg,
    //   symbol: "rsETH",
    //   name: "rsETH",
    //   decimals: 18,
    //   address: "0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://kelpdao.xyz/restake/",
    //   selectTag: ["eth", "LRT"],
    //   boostTag: [],
    // },
    // {
    //   chain: "eth",
    //   icon: RswethImg,
    //   symbol: "rswETH",
    //   name: "rswETH",
    //   decimals: 18,
    //   address: "0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0",
    //   minAmount: 0.0001,
    //   showInGallery: true,
    //   showInStakeList: true,
    //   showInUnstakeList: true,
    //   url: "https://app.swellnetwork.io/restake",
    //   selectTag: ["eth", "LRT"],
    //   boostTag: [],
    // },
    {
      chain: "near",
      icon: LinearImg,
      symbol: "LiNEAR",
      name: "LiNEAR",
      decimals: 24,
      address: "linear-protocol.near",
      minAmount: 0.1,
      url: "https://app.linearprotocol.org/",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["near", "LST"],
      boostTag: [],
    },
    {
      chain: "near",
      icon: StNearImg,
      symbol: "stNEAR",
      name: "stNEAR",
      decimals: 24,
      address: "meta-pool.near",
      minAmount: 0.1,
      url: "https://www.metapool.app/stake/?token=near",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["near", "LST"],
      boostTag: [],
    },
    {
      chain: "near",
      icon: AuroraImg,
      symbol: "Aurora",
      name: "Aurora",
      decimals: 18,
      address: "aaaaaa20d9e0e2461697782ef11675f668207961.factory.bridge.near",
      minAmount: 0.1,
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["near", "Governance"],
      boostTag: [],
    },
    {
      chain: "near",
      icon: NearImg,
      symbol: "NEAR",
      name: "NEAR~LiNEAR",
      decimals: 24,
      address: "linear-protocol.near",
      minAmount: 1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ["near", "Native"],
      boostTag: [],
    },
    {
      chain: "near",
      icon: NearImg,
      symbol: "NEAR",
      name: "NEAR~stNEAR",
      decimals: 24,
      address: "meta-pool.near",
      minAmount: 1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ["near", "Native"],
      boostTag: [],
    },
    {
      chain: "solana",
      icon: MsolImg,
      symbol: "mSOL",
      name: "mSOL",
      decimals: 9,
      address: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
      minAmount: 0.01,
      url: "https://marinade.finance/app/stake/?input=SOL",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["solana", "LST"],
      boostTag: [],
    },
    {
      chain: "solana",
      icon: JitoSolImg,
      symbol: "jitoSOL",
      name: "jitoSOL",
      decimals: 9,
      address: "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
      minAmount: 0.01,
      url: "https://www.jito.network/staking/",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["solana", "LST"],
      boostTag: [],
    },
    {
      chain: "solana",
      icon: InfImg,
      symbol: "INF",
      name: "INF",
      decimals: 9,
      address: "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
      minAmount: 0.01,
      url: "https://app.sanctum.so/infinity",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["solana", "LST"],
      boostTag: [],
    },
    {
      chain: "solana",
      icon: BSolImg,
      symbol: "bSOL",
      name: "bSOL",
      decimals: 9,
      address: "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
      minAmount: 0.01,
      url: "https://stake.solblaze.org/app",
      showInGallery: true,
      showInStakeList: true,
      showInUnstakeList: true,
      selectTag: ["solana", "LST"],
      boostTag: [],
    },
    {
      chain: "solana",
      icon: SolanaImg,
      symbol: "SOL",
      name: "SOL~mSOL",
      decimals: 9,
      address: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
      minAmount: 0.1,
      showInGallery: false,
      showInStakeList: true,
      showInUnstakeList: false,
      selectTag: ["solana", "Native"],
      boostTag: [],
    },
    // {
    //   chain: "solana",
    //   icon: SolanaImg,
    //   symbol: "SOL",
    //   name: "SOL-jitoSOL",
    //   decimals: 9,
    //   address: "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
    //   minAmount: 0.1,
    //   url: "https://www.jito.network/staking/",
    //   showInGallery: false,
    //   showInStakeList: true,
    //   showInUnstakeList: false,
    // },
  ],
  pointsApiUrl: "https://points-api-staging.allstake.org",
  supportNetworks: ["near", "solana", "eth"],
  dropdownAssets: [
    "Native",
    "LST",
    "LRT",
    "Governance",
    "Stablecoin",
    "Pendle",
  ],
  dropdownNetworks: ["eth", "near", "solana", "btc"],
  twitterApiServer: "",
};

export default config;
