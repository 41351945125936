import { twc } from "react-twc";
import Step1 from "./Step1";
import { useEffect, useState } from "react";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import clsx from "clsx";
import { XImage } from "./components";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import { useWallet } from "@solana/wallet-adapter-react";
import useQuery from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import {
  authorizeCallback,
  follow,
  GemUser,
  getUser,
  getUserByChainAddress,
  register,
} from "../../utils/twitterApiInstance";
import { signMessageToBase64 } from "../../utils/signMessage";
import {
  getNearMessageCallbackUrl,
  getTwitterKeyFromLocalStorage,
  remoteTwitterKeyFromLocalStorage,
  setNearSignMessageCallbackUrl,
  setTwitterDataToLocalStorage,
} from "../../utils/localStorage";
import config from "../../config";
import { GEM_CHAINID_MAPPING, localStorageKey } from "../../constants";
import AllstakeModal from "../../components/modal";
import { SymbolInput } from "../../config/type";
import useChain, { ChainId } from "../../hooks/useChain";
import { useSearchParam } from "react-use";
import { useSignMessage } from "wagmi";
import { formatToReadableError } from "../../utils/error";
import { useTranslation } from "react-i18next";
import { LoadingCircleImg } from "../../assets";

function Campaign() {
  const { t } = useTranslation();
  const { selector } = useWalletSelector();
  const [step, setStep] = useState<number>(0);
  const { signMessage: signMessageSolana } = useWallet();
  const chain = useChain();
  const navigate = useNavigate();
  const query = useQuery();
  const chainFromQueryParams = query.get("chain");
  const chainQuery = chainFromQueryParams
    ? `?chain=${chainFromQueryParams}`
    : "";
  const [gemUser, setGemUser] = useState<GemUser | null>(null);
  const [error, setError] = useState("");
  const { signMessageAsync: signMessageEthAsync } = useSignMessage();

  // the hooks detect url params and set params to localstorage
  useEffect(() => {
    const state = query.get("state");
    const code = query.get("code");
    if (state && code) {
      let token: SymbolInput | undefined = undefined;

      const chainQuery = localStorage.getItem(
        localStorageKey.campaign_chain_query,
      );
      if (!!chainQuery) {
        const chain = new URLSearchParams(chainQuery).get("chain");
        token = config.restakingTokens.find((token) => token.chain === chain);
      } else {
        token = config.restakingTokens[0];
      }

      if (!token) {
        return;
      }
      const addressFromLocalStorage = localStorage.getItem(token.chain);
      if (!addressFromLocalStorage) {
        return;
      }
      setTwitterDataToLocalStorage(
        localStorageKey.twitter_state,
        token.chain,
        addressFromLocalStorage,
        state,
      );
      setTwitterDataToLocalStorage(
        localStorageKey.twitter_code,
        token.chain,
        addressFromLocalStorage,
        code,
      );
      const url = `/campaign${chainQuery}`;
      navigate(url);
    } else {
      if (!chain || !chain.chainId || !chain.address) {
        return;
      }
      localStorage.setItem(chain.chainId, chain.address ?? "");
      localStorage.setItem(localStorageKey.campaign_chain_query, chainQuery);
    }
  }, [chain?.chainId, chain?.address]);

  const fetchUserByChainAddress = async () => {
    if (!chain || !chain.address || !chain?.chainId) {
      return null;
    }
    const user = await getUserByChainAddress(
      GEM_CHAINID_MAPPING[chain.chainId],
      chain.address,
    );
    if (user) {
      setGemUser(user);
    }
    return user;
  };

  const fetchUserByTwitterState = async () => {
    if (!chain || !chain.address || !chain?.chainId) {
      return null;
    }
    const twitterState = getTwitterKeyFromLocalStorage(
      localStorageKey.twitter_state,
      chain.chainId,
      chain.address,
    );
    if (twitterState) {
      // check twitter login status
      const user = await getUser({
        state: twitterState,
      });
      if (user?.success) {
        setGemUser(user.data);
        return user.data;
      }
    }
    return null;
  };

  async function handleTwitterAlreadyInUseError() {
    const user = await fetchUserByTwitterState();
    const userByChainAddress = await fetchUserByChainAddress();
    if (userByChainAddress) {
      setError(
        t("error.addressAlreadyInUse", {
          twitter_account: userByChainAddress?.twitter_username,
          chain: userByChainAddress?.chain,
          address: userByChainAddress?.address,
        }),
      );
    } else {
      setError(
        t("error.twitterAlreadyInUse", {
          twitter_account: user?.twitter_username,
          chain: user?.chain,
          address: user?.address,
        }),
      );
    }
  }

  useEffect(() => {
    (async function () {
      setStep(0);
      if (!chain) {
        return;
      }
      if (!chain.address || !chain.chainId) {
        setStep(1);
        return;
      }
      let currentStep = 1;

      const processTwitterCallback = async ({
        twitterState,
        twitterCode,
        signMessage,
        chain,
        address,
      }: {
        twitterCode: string;
        twitterState: string;
        signMessage: (msg: string) => Promise<{
          signature: string | null;
          publicKey?: string; // only near
        } | null>;
        chain: ChainId;
        address: string;
      }) => {
        const data = await authorizeCallback(twitterCode, twitterState);
        remoteTwitterKeyFromLocalStorage("twitter_code", chain, address);
        if (data?.data) {
          const status = data.data.status;
          const authorizationMessage = data.data.authorization_message;
          if (status === "register") {
            try {
              const signResponse = await signMessage(authorizationMessage);
              if (!signResponse?.signature) {
                throw new Error("Sign message failed");
              }
              const registerResponse = await register({
                chain,
                address,
                signature: signResponse.signature,
                state: twitterState,
                publicKey: signResponse.publicKey,
              });
              if (registerResponse.success) {
                currentStep = 3;
              }
            } catch (e) {
              const readableError = formatToReadableError(e, t);
              if (
                readableError ===
                "address has already been connected with a twitter id"
              ) {
                await handleTwitterAlreadyInUseError();
              } else {
                setError(formatToReadableError(e, t));
              }
            }
          } else if (status === "follow") {
            const user = await fetchUserByTwitterState();
            if (
              user?.chain !== GEM_CHAINID_MAPPING[chain] ||
              user.address !== address
            ) {
              currentStep = 2;
              await handleTwitterAlreadyInUseError();
              remoteTwitterKeyFromLocalStorage("twitter_state", chain, address);
            } else {
              if (user.twitter_followed) {
                currentStep = 4;
              } else {
                currentStep = 3;
              }
            }
          } else {
            const user = await fetchUserByTwitterState();
            if (
              user?.chain !== GEM_CHAINID_MAPPING[chain] ||
              user.address !== address
            ) {
              currentStep = 2;
              await handleTwitterAlreadyInUseError();
              remoteTwitterKeyFromLocalStorage("twitter_state", chain, address);
            } else {
              if (user.twitter_followed) {
                currentStep = 4;
              } else {
                currentStep = 3;
              }
            }
          }
        }
      };

      // check connected wallet
      if (chain.isDirectConnected) {
        currentStep = 2;
      }

      if (currentStep === 2) {
        // check twitter auth
        const twitterCode = getTwitterKeyFromLocalStorage(
          localStorageKey.twitter_code,
          chain.chainId,
          chain.address,
        );
        const twitterState = getTwitterKeyFromLocalStorage(
          localStorageKey.twitter_state,
          chain.chainId,
          chain.address,
        );

        const userByChainAddress = await fetchUserByChainAddress();
        if (userByChainAddress?.twitter_followed) {
          setStep(4);
          return;
        }
        const user = await fetchUserByTwitterState();
        if (user) {
          if (
            user?.chain !== GEM_CHAINID_MAPPING[chain.chainId] ||
            user.address !== chain.address
          ) {
            setStep(2);
            await handleTwitterAlreadyInUseError();
            remoteTwitterKeyFromLocalStorage(
              localStorageKey.twitter_state,
              chain.chainId,
              chain.address,
            );
          } else {
            if (user.twitter_followed) {
              setStep(4);
            } else {
              setStep(3);
            }
          }
          return;
        }
        if (twitterCode && twitterState) {
          if (
            chain.chainId === "solana" &&
            chain.address &&
            signMessageSolana
          ) {
            const signMessage = async (msg: string) => ({
              signature: await signMessageToBase64(signMessageSolana, msg),
            });
            if (!signMessage) {
              return;
            }
            await processTwitterCallback({
              twitterCode,
              twitterState,
              signMessage,
              chain: "solana",
              address: chain.address,
            });
          } else if (chain.chainId === "near" && chain.address) {
            const nearWallet = await selector.wallet();
            const callbackUrl =
              window.allstake_near_wallet_id === "my-near-wallet"
                ? window.location.href
                : undefined;
            const signMessage = async (msg: string) => {
              if (callbackUrl) {
                setNearSignMessageCallbackUrl(callbackUrl);
              }
              const signedMessage = await nearWallet.signMessage({
                message: msg,
                recipient: "allstake",
                nonce: Buffer.alloc(32),
                callbackUrl,
              });
              if (signedMessage === void 0) {
                return null;
              }
              return {
                signature: signedMessage.signature,
                publicKey: signedMessage.publicKey,
              };
            };
            await processTwitterCallback({
              twitterCode,
              twitterState,
              signMessage,
              chain: "near",
              address: chain.address,
            });
          } else if (chain.chainId === "eth" && chain.address) {
            const signMessage = async (message: string) => {
              const signature = (await signMessageEthAsync({
                message,
              })) as string;
              return {
                signature,
              };
            };
            await processTwitterCallback({
              twitterCode,
              twitterState,
              signMessage,
              chain: "eth",
              address: chain.address,
            });
          }
        }
      }
      setStep(currentStep);
    })();
  }, [
    chain?.isSignedIn,
    chain?.chainId,
    chain?.address,
    signMessageSolana,
    selector,
  ]);

  // handle web wallet sign message
  useEffect(() => {
    (async function () {
      if (!chain) return;
      const queryString = window.location.hash.substring(1);
      const params = new URLSearchParams(queryString);
      const accountId = params.get("accountId");
      const signature = params.get("signature");
      const publicKey = params.get("publicKey");
      if (
        !accountId ||
        !signature ||
        !publicKey ||
        !chain.chainId ||
        !chain.address
      )
        return;
      const twitterState = getTwitterKeyFromLocalStorage(
        localStorageKey.twitter_state,
        chain.chainId,
        chain.address,
      );
      if (!twitterState) return;
      try {
        const registerResponse = await register({
          chain: chain.chainId,
          address: chain.address,
          signature,
          state: twitterState,
          publicKey,
          callbackUrl: getNearMessageCallbackUrl() ?? undefined,
        });
        if (registerResponse.success) {
          setStep(3);
          navigate(`/campaign${chainQuery}`);
        }
      } catch (e) {
        const readableError = formatToReadableError(e, t);
        if (
          readableError ===
          "address has already been connected with a twitter id"
        ) {
          await handleTwitterAlreadyInUseError();
        } else {
          setError(formatToReadableError(e, t));
        }
      }
    })();
  }, [chain?.chainId, chain?.address, window.location.hash]);

  // check inviteCode
  const inviteCode = useSearchParam("inviteCode");
  useEffect(() => {
    if (inviteCode) {
      localStorage.setItem(
        localStorageKey.allstake_campaign_invite_code,
        inviteCode,
      );
    }
  }, [inviteCode]);

  if (step === 0) {
    return (
      <div className="w-full min-h-[70vh] grid place-content-center">
        <img className="w-20 h-20" src={LoadingCircleImg} />
      </div>
    );
  }
  return (
    <>
      <CampaignOuter>
        <CampaignStyle>
          <CampaignTitle>{t("campaign.title")}</CampaignTitle>
          <StepsContainer>
            <StepContainer className={clsx(step !== 1 && "opacity-40")}>
              <StepRectangle />
              <StepItemText>{t("campaign.connectWallet")}</StepItemText>
            </StepContainer>
            <StepContainer className={clsx(step !== 2 && "opacity-40")}>
              <StepRectangle />
              <StepItemText>
                {t("campaign.connect")} <XImage className="ml-1" />
              </StepItemText>
            </StepContainer>
            <StepContainer className={clsx(step !== 3 && "opacity-40")}>
              <StepRectangle />
              <StepItemText>
                {t("campaign.follow")} <XImage />
              </StepItemText>
            </StepContainer>
            <StepContainer className={clsx(step !== 4 && "opacity-40")}>
              <StepRectangle />
              <StepItemText>{t("campaign.claimGems")}</StepItemText>
            </StepContainer>
          </StepsContainer>
          {step === 1 && <Step1 />}
          {step === 2 && <Step2 />}
          {step === 3 && (
            <Step3
              onClickFollow={async () => {
                if (!chain || !chain.chainId || !chain.address) {
                  return;
                }
                const twitterState = getTwitterKeyFromLocalStorage(
                  localStorageKey.twitter_state,
                  chain.chainId,
                  chain.address,
                );
                if (twitterState) {
                  const followResponse = await follow({ state: twitterState });
                  if (followResponse?.success) {
                    setStep(4);
                    await fetchUserByTwitterState();
                  } else {
                    remoteTwitterKeyFromLocalStorage(
                      localStorageKey.twitter_state,
                      chain.chainId,
                      chain.address,
                    );
                    setError((followResponse as any)?.response?.data?.message);
                    setStep(2);
                  }
                }
              }}
            />
          )}
          {step === 4 && <Step4 gemUser={gemUser} />}
        </CampaignStyle>
      </CampaignOuter>
      {!!error && (
        <AllstakeModal
          onRequestClose={() => {
            setError("");
            localStorage.removeItem(
              localStorageKey.allstake_campaign_invite_code,
            );
          }}
          varient="error"
          title="error.error"
          description={error}
          buttons={[
            {
              varient: "primary",
              text: "modal.gotIt",
              onClick: () => {
                setError("");
                localStorage.removeItem(
                  localStorageKey.allstake_campaign_invite_code,
                );
              },
            },
          ]}
        />
      )}
    </>
  );
}

const StepItemText = twc.div`
  text-sm font-semibold
  flex items-center gap-1
`;

const StepContainer = twc.div`
  flex flex-col items-center gap-1
`;

const StepRectangle = twc.div`
  w-full h-1 bg-white
`;

const StepsContainer = twc.div`
  grid grid-cols-4 gap-1 w-full
`;

const CampaignTitle = twc.h1`
  text-[40px] font-semibold text-center
`;

const CampaignOuter = twc.div`
  px-4 xl:px-0 
`;

const CampaignStyle = twc.div`
  w-full xl:w-[1000px] h-[80vh] m-auto my-10
 bg-[#0C111D59] rounded-[20px] backdrop-blur-sm
  p-10
  flex flex-col gap-8
`;

export default Campaign;
