import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import useChain from "../../hooks/useChain";
import {
  ContentContainer,
  ContentTitle,
  ContentItem,
  Opacity80,
  GemImage,
  GemColor,
  StepContainer,
  PrimaryButton,
} from "./components";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useTranslation } from "react-i18next";
import { useAccount, useWalletClient } from "wagmi";
import { resetWagmiState } from "../../utils/wagmi";

function Step1() {
  const chain = useChain();
  const { modal: nearWalletModal } = useWalletSelector();
  const solanaWalletModal = useWalletModal();
  const { open: ethModalOpen } = useWeb3Modal();
  const { t } = useTranslation();
  const ethAccount = useAccount();
  const { data: walletClient } = useWalletClient();
  return (
    <StepContainer>
      <ContentContainer>
        <ContentTitle>{t("campaign.connectYourWallet")}</ContentTitle>
        <ContentItem>
          <Opacity80>{t("campaign.startYourRestakingJourney")}</Opacity80>
          <GemImage className="mx-1" />
          <GemColor className="mr-1">{t("campaign.allstakeGems")}</GemColor>.
        </ContentItem>
      </ContentContainer>
      <div className="items-end justify-end">
        <PrimaryButton
          onClick={() => {
            if (!chain) return;
            if (chain.chainId === "near") {
              // near
              nearWalletModal.show();
            } else if (chain.chainId === "solana") {
              // solana
              solanaWalletModal.setVisible(true);
            } else if (chain.chainId === "eth") {
              // eth
              if (!walletClient && ethAccount.isConnected) {
                // reset wagmi state
                resetWagmiState();
                return;
              }
              ethModalOpen();
            }
          }}
        >
          {t("campaign.connectYourWallet")}
        </PrimaryButton>
      </div>
    </StepContainer>
  );
}

export default Step1;
