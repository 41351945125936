import { useState } from "react";
import {
  ContentContainer,
  ContentTitle,
  XImage,
  ContentItem,
  Opacity80,
  GemImage,
  GemColor,
  StepContainer,
  AllStakeFi,
  PrimaryButton,
} from "./components";
import { LoadingCircleImg } from "../../assets";
import { useTranslation } from "react-i18next";

function Step3({ onClickFollow }: { onClickFollow: () => void }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <StepContainer>
      <ContentContainer>
        <ContentTitle>
          {t("campaign.follow")} <XImage /> @allstake_fi
        </ContentTitle>
        <ContentItem>
          <Opacity80>{t("campaign.followAllstake")}</Opacity80>
          <AllStakeFi className="mx-1" />
          <Opacity80>{t("campaign.on")}</Opacity80>
          <XImage className="mx-1" />
          <Opacity80>{t("campaign.toEarn")}</Opacity80>
          <GemImage className="mx-1" />
          <GemColor>{t("campaign.plus300Gems")}</GemColor>
          <Opacity80>{t("campaign.theGemsWillBeCredited")}</Opacity80>
        </ContentItem>
      </ContentContainer>
      <div className="items-end justify-end">
        <PrimaryButton
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await onClickFollow();
            setLoading(false);
          }}
        >
          {!loading && (
            <>
              {t("campaign.followAllstakeOn")} <XImage className="ml-1" />
            </>
          )}
          {loading && (
            <img className="rotate-infinite w-5 h-5" src={LoadingCircleImg} />
          )}
        </PrimaryButton>
      </div>
    </StepContainer>
  );
}

export default Step3;
