import { twc } from "react-twc";
import { ArrowDownImg, YesImg } from "../../assets";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import SymbolInputContext from "../../context/SymbolInputContext";
import useAllstakeSdk from "../../hooks/useAllstakeSdk";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import AllstakeModal from "../modal";
import BalanceContext from "../../context/BalanceContext";
import { useLocation, useNavigate } from "react-router-dom";
import { HOUR, MINUTE } from "../../utils/time";
import dayjs from "dayjs";
import useWithdrawAmount from "../../hooks/useWithdrawAmount";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import useQuery from "../../hooks/useQuery";
import { getNear, isWebWallet } from "../../utils/wallet";
import { parseOutcomeValue } from "allstake-sdk";
import { getMethodNamesFromArgs } from "../../utils/near";
import clsx from "clsx";
import RefreshBalanceContext from "../../context/RefreshBalanceContext";
import config from "../../config";
import { SymbolInput } from "../../config/type";
import { formatShownDigit } from "../../utils/number";
import { useTranslation } from "react-i18next";
import AllStakeTips from "../tips";
import { formatToReadableError } from "../../utils/error";
import { useAccount, usePublicClient } from "wagmi";
import LoadingCircle from "../loading/LoadingCircle";
import useChain from "../../hooks/useChain";
import { waitForTransactionReceiptRecursion } from "../../utils/erc20";
import { extractToTokenNameByName } from "../../utils/regex";

const { useSymbolInputTracked } = SymbolInputContext;
const { useBalanceTracked } = BalanceContext;
const { useRefreshBalanceTracked } = RefreshBalanceContext;

function YourRestakedAmount() {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [, setRefreshBalance] = useRefreshBalanceTracked();

  const allstakeSdk = useAllstakeSdk();
  const wallet = useWallet();
  const [symbolInput, setSymbolInput] = useSymbolInputTracked();
  const { withdrawAmount, withdrawAvailableTime, disabledWithdrawDueTime } =
    useWithdrawAmount(refresh, setRefresh);
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();

  const formattedWithdrawAvailableTime = useMemo(() => {
    if (!withdrawAvailableTime) return "-";
    const hours = (withdrawAvailableTime - Date.now()) / HOUR;
    if (hours > 1) {
      return `~${Math.ceil(hours)} ${t("portfolio.hours")}`;
    } else {
      const minutes = (withdrawAvailableTime - Date.now()) / MINUTE;
      const shownMinutes = Math.ceil(minutes);
      if (shownMinutes === 1) {
        return `~1 ${t("portfolio.minute")}`;
      } else {
        return `~${shownMinutes} ${t("portfolio.minutes")}`;
      }
    }
  }, [withdrawAvailableTime]);

  const [showModal, setShowModal] = useState<"success" | "error">();
  const [error, setError] = useState("");
  const [balance] = useBalanceTracked();
  const disabledWithdraw =
    withdrawAmount.raw.eq(new BN(0)) || disabledWithdrawDueTime;
  const navigate = useNavigate();
  const chain = useChain();
  const { accountId } = useWalletSelector();

  const { address: ethAddress } = useAccount();

  const query = useQuery();
  const symbol = query.get("symbol");
  const txHashes = query.get("transactionHashes");
  const errorCode = query.get("errorCode");
  const location = useLocation();

  const publicClient = usePublicClient();
  useEffect(() => {
    (async function () {
      if (errorCode) {
        navigate(`${location.pathname}${symbol ? `?symbol=${symbol}` : ""}`);
      } else {
        if (!txHashes || !accountId) return;
        if (txHashes) {
          const near = await getNear();
          const navigatePathname = location.pathname;
          try {
            for (const txHash of txHashes.split(",")) {
              parseOutcomeValue(
                await near.connection.provider.txStatus(txHash, accountId),
              );
            }
            const methodNames = await getMethodNamesFromArgs(
              txHashes.split(","),
              accountId,
            );
            if (methodNames.includes("complete_queued_withdrawals")) {
              setShowModal("success");
            }
          } catch (e) {
            setShowModal("error");
            setError(formatToReadableError(e, t));
            throw e;
          } finally {
            navigate(`${navigatePathname}${symbol ? `?symbol=${symbol}` : ""}`);
          }
        }
      }
    })();
  }, []);
  const unstakeButton = (
    <DButton
      disabled={!disabledWithdraw}
      onClick={() => {
        const newQuery = new URLSearchParams();
        const symbol = query.get("symbol");
        const chain = query.get("chain");
        if (symbol) {
          newQuery.set("symbol", extractToTokenNameByName(symbol));
        } else if (chain) {
          newQuery.set("chain", chain);
        }
        navigate(`/?${newQuery.toString()}&tab=unstake`);
      }}
    >
      {t("portfolio.unstake")}
    </DButton>
  );

  if (!symbolInput || !chain) return null;

  return (
    <YourRestakedAmountContainer>
      <div style={{ display: "none" }}>
        <p>{withdrawAmount.formatted}</p>
        <p>{withdrawAvailableTime}</p>
        <p>{disabledWithdrawDueTime}</p>
      </div>
      <TitleSection>
        <Title>{t("portfolio.yourRestakedAmount")}</Title>
        <SelectedToken>
          <button
            className="w-full flex justify-between items-center"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className="flex items-center gap-3">
              <img
                className="rounded-full overflow-hidden"
                src={symbolInput.icon}
                width={28}
                height={28}
              />
              <p>{symbolInput.symbol}</p>
            </div>
            <img src={ArrowDownImg} width={20} height={20} />
          </button>
          {showDropdown && (
            <DropdownContainer>
              {config.restakingTokens
                .filter(
                  (token: SymbolInput) =>
                    token.chain === chain.chainId && token.showInGallery,
                )
                .map((token: SymbolInput, idx) => (
                  <DropdownItem
                    key={idx}
                    className={clsx(
                      symbolInput.symbol === token.symbol && "bg-[#1F242F]",
                    )}
                    onClick={() => {
                      setSymbolInput(token);
                      setShowDropdown(false);
                      setLoading(false);
                      navigate(`${location.pathname}?symbol=${token.name}`);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        className="rounded-full overflow-hidden"
                        src={token.icon}
                        width={20}
                        height={20}
                      />
                      <p className="whitespace-nowrap">{token.symbol}</p>
                    </div>
                    {symbolInput.symbol === token.symbol && (
                      <img src={YesImg} width={20} height={20} />
                    )}
                  </DropdownItem>
                ))}
            </DropdownContainer>
          )}
        </SelectedToken>
      </TitleSection>

      <TwoColumn>
        <DarkContainer>
          <DTitle>
            {t("portfolio.availableInWallet", {
              symbol: symbolInput.symbol,
            })}
          </DTitle>
          <DValue>
            {formatShownDigit(balance[symbolInput.address]?.tokenBalance)}
          </DValue>
          <DButton
            onClick={() => {
              const newQuery = new URLSearchParams();
              const symbol = query.get("symbol");
              const chain = query.get("chain");
              if (symbol) {
                newQuery.set("symbol", extractToTokenNameByName(symbol));
              } else if (chain) {
                newQuery.set("chain", chain);
              }
              navigate(`/?${newQuery.toString()}`);
            }}
          >
            {t("portfolio.deposit")}
          </DButton>
        </DarkContainer>
        <DarkContainer>
          <DTitle>
            {t("portfolio.yourRestakedSymbol", {
              symbol: symbolInput.symbol,
            })}
          </DTitle>
          <DValue>
            {formatShownDigit(balance[symbolInput.address]?.stakedToken)}
          </DValue>
          {!disabledWithdraw ? (
            <AllStakeTips trigger={<div>{unstakeButton}</div>}>
              {t("modal.goToWithdrawDescription", {
                symbol: symbolInput.symbol,
              })}
            </AllStakeTips>
          ) : (
            unstakeButton
          )}
        </DarkContainer>
      </TwoColumn>
      {withdrawAmount.raw.gt(new BN(0)) && symbolInput && (
        <DarkContainer>
          <div className="grid grid-cols-2">
            <div className="LeftPart">
              {disabledWithdrawDueTime ? (
                <DTitle>{t("portfolio.pendingUnstake")}</DTitle>
              ) : (
                <DTitle>
                  {t("portfolio.withdrawableAmount", {
                    symbol: symbolInput.symbol,
                  })}
                </DTitle>
              )}
              <DValue>{withdrawAmount.formatted}</DValue>
            </div>
            {disabledWithdrawDueTime && withdrawAvailableTime && (
              <div className="RightPart">
                <DTitle>{t("portfolio.remaining")}</DTitle>
                <DValue showIcon={false}>
                  {formattedWithdrawAvailableTime}
                </DValue>
              </div>
            )}
          </div>
          <DButton
            loading={loading}
            disabled={disabledWithdraw}
            onClick={async () => {
              if (!symbolInput || !chain) return;
              if (chain.chainId === "solana") {
                if (
                  !allstakeSdk ||
                  !allstakeSdk.solana ||
                  !allstakeSdk.solana.strategyManagerProgram ||
                  !wallet?.publicKey
                ) {
                  return;
                }
                setLoading(true);
                try {
                  await allstakeSdk.solana.strategyManagerProgram.completeWithdraw(
                    new PublicKey(symbolInput.address),
                  );
                  setShowModal("success");
                } catch (e) {
                  setShowModal("error");
                  setError(formatToReadableError(e, t));
                  throw e;
                } finally {
                  setLoading(false);
                  setRefresh(true);
                  setRefreshBalance(true);
                }
              } else if (chain.chainId === "near") {
                if (
                  !allstakeSdk ||
                  !allstakeSdk.near ||
                  !allstakeSdk.near.strategyManagerContract ||
                  !accountId
                ) {
                  return;
                }
                setLoading(true);
                try {
                  const strategies =
                    await allstakeSdk.near.strategyManagerContract.getStrategies(
                      {},
                    );
                  const withdrawal =
                    await allstakeSdk.near.strategyManagerContract.getQueuedWithdrawals(
                      {
                        staker: accountId,
                      },
                    );
                  const withdrawals: Array<{
                    staker: string;
                    operator?: string;
                    withdrawer: string;
                    nonce: number;
                    start_at: number;
                    strategies: string[];
                    shares: string[];
                  }> = [];
                  withdrawal.forEach((withdraw) => {
                    const targetStrategies: string[] = [];
                    const targetShares: string[] = [];
                    const targetStrategiesStartAt: number = withdraw.start_at;
                    const targetNonce: number = withdraw.nonce;

                    const withdrawStrategies = withdraw.strategies;
                    const withdrawShares = withdraw.shares;
                    withdrawStrategies.forEach((withdrawStrategyId, idx) => {
                      const strategy = strategies.find(
                        (strategy) => strategy.id === withdrawStrategyId,
                      );
                      if (
                        strategy &&
                        strategy.underlying_token === symbolInput.address
                      ) {
                        targetStrategies.push(strategy.id);
                        targetShares.push(withdrawShares[idx]);
                      }
                    });
                    if (
                      targetStrategies.length > 0 &&
                      targetShares.length > 0
                    ) {
                      withdrawals.push({
                        staker: accountId,
                        withdrawer: accountId,
                        nonce: targetNonce,
                        start_at: targetStrategiesStartAt,
                        strategies: targetStrategies,
                        shares: targetShares,
                      });
                    }
                  });
                  await allstakeSdk.near.strategyManagerContract.completeQueuedWithdrawals(
                    {
                      withdrawals: withdrawals,
                    },
                  );
                  setShowModal("success");
                } catch (e) {
                  if (!(await isWebWallet())) {
                    setShowModal("error");
                  }
                  setError(formatToReadableError(e, t));
                  throw e;
                } finally {
                  setLoading(false);
                  setRefresh(true);
                }
              } else if (chain.chainId === "eth") {
                if (
                  !allstakeSdk ||
                  !allstakeSdk.ethereum ||
                  !allstakeSdk.ethereum.strategyManagerContract ||
                  !ethAddress ||
                  !publicClient
                ) {
                  return;
                }
                setLoading(true);
                try {
                  const userStrategyData =
                    await allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
                      symbolInput.address,
                      ethAddress,
                    );
                  const requests =
                    await allstakeSdk.ethereum.uiDataProviderContract.userQueueWithdrawalRequests(
                      symbolInput.address,
                      ethAddress,
                    );
                  const txHash =
                    await allstakeSdk.ethereum.strategyManagerContract.completeWithdrawFromStrategy(
                      userStrategyData.strategy,
                      requests.map((request) => BigInt(request.id)),
                    );
                  await waitForTransactionReceiptRecursion(
                    publicClient,
                    txHash,
                  );
                  setShowModal("success");
                  setRefresh(true);
                  setRefreshBalance(true);
                } catch (e) {
                  setShowModal("error");
                  setError(formatToReadableError(e, t));
                  throw e;
                } finally {
                  setLoading(false);
                }
              }
            }}
          >
            {t("portfolio.withdraw")}
          </DButton>
          {withdrawAvailableTime && disabledWithdrawDueTime && (
            <WithdrawAvailable>
              {t("portfolio.withdrawAvailable", {
                time: dayjs(withdrawAvailableTime).format(
                  "YYYY/MM/DD HH:mm:ss",
                ),
              })}
            </WithdrawAvailable>
          )}
        </DarkContainer>
      )}
      {showModal === "success" && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => {
            setShowModal(undefined);
            setRefresh(true);
            setRefreshBalance(true);
          }}
          title="modal.withdrawSuccess"
          description=""
          buttons={[
            {
              varient: "primary",
              text: "modal.gotIt",
              onClick: () => {
                setShowModal(undefined);
                setRefresh(true);
                setRefreshBalance(true);
              },
            },
          ]}
        />
      )}
      {showModal === "error" && (
        <AllstakeModal
          varient="error"
          onRequestClose={() => setShowModal(undefined)}
          title="modal.withdrawFailure"
          description={error}
          buttons={[
            {
              varient: "outline",
              text: "modal.back",
              onClick: () => setShowModal(undefined),
            },
          ]}
        />
      )}
    </YourRestakedAmountContainer>
  );
}

const SelectedToken = twc.div`
  py-2 px-3 h-[44px] min-w-[160px]
  rounded-full
  border border-[#FFFFFF24]
  bg-[#1F2636]
  cursor-pointer select-none
  flex justify-between items-center gap-3
  font-semibold relative
`;

const TitleSection = twc.section`
  flex justify-between
`;

const DropdownItem = twc.div`
  flex items-center justify-between gap-2 py-3 px-2
  hover:bg-[#1F242F]
  rounded-md cursor-pointer
`;

const DropdownContainer = twc.div`
  absolute top-[60px] max-h-[320px] overflow-auto
  left-0 w-full
  rounded-md
  bg-[#0C111D]
  border border-[#1F242F]
  p-2
  flex flex-col gap-2
  z-10
`;

const WithdrawAvailable = twc.div`
  mt-4
  text-sm text-white text-opacity-60 text-center
`;

const DValue = ({
  children,
  showIcon = true,
}: PropsWithChildren & {
  showIcon?: boolean;
}) => {
  const [symbolInput] = useSymbolInputTracked();
  return (
    <DValueStyle>
      <div>{children}</div>
      {showIcon && (
        <img
          className="rounded-full"
          src={symbolInput?.icon}
          width={32}
          height={32}
        />
      )}
    </DValueStyle>
  );
};

const DTitle = twc.div`
  text-white text-opacity-80
`;

const DValueStyle = twc.div`
  flex items-center gap-1
  mt-1 mb-6
  text-[22px] font-bold
`;

const DButton = ({
  children,
  onClick,
  loading,
  disabled,
}: PropsWithChildren & {
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <DButtonStyle disabled={disabled || loading} onClick={onClick}>
      {loading && <LoadingCircle />}
      {!loading && children}
    </DButtonStyle>
  );
};
const DButtonStyle = twc.button`
  disabled:opacity-50 disabled:cursor-not-allowed 
  duration-300
  w-full rounded-full bg-[#1F2636]
  border border-white border-opacity-10
  h-[56px] flex justify-center items-center
  text-xl font-semibold
`;

const DarkContainer = twc.div`
  p-10 rounded-xl bg-[#0C111D]
`;

const TwoColumn = twc.div`
  grid grid-cols-2 gap-8
`;

const YourRestakedAmountContainer = twc.div`
  mt-[20vh] w-full md:w-[700px] 
  m-auto
  flex flex-col gap-5
`;

const Title = twc.div`
  text-xl font-semibold
`;

export default YourRestakedAmount;
