import axios from "axios";
import config from "../config";
import {
  Gem_Chain_Id_Type,
  GEM_CHAINID_MAPPING,
  localStorageKey,
} from "../constants";
import { ChainId } from "../hooks/useChain";

const twitterApiInstance = axios.create({
  baseURL: config.twitterApiServer,
  headers: {
    "ngrok-skip-browser-warning": "true",
  },
});
export default twitterApiInstance;

type Status = "ok" | "follow" | "register";

export async function authorizeCallback(
  twitterCode: string,
  twitterState: string,
) {
  try {
    const response = await twitterApiInstance.get<{
      data?: {
        status: Status;
        authorization_message: string;
      };
    }>(`/authorize_callback?code=${twitterCode}&state=${twitterState}`);
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export type RegisterRequestBody = {
  chain: ChainId;
  address: string;
  signature: string;
  publicKey?: string;
  state: string;
  referralCode?: string;
  callbackUrl?: string;
};

export async function register({
  chain,
  address,
  signature,
  publicKey,
  state,
  callbackUrl,
}: RegisterRequestBody) {
  const referralCode = localStorage.getItem(
    localStorageKey.allstake_campaign_invite_code,
  );
  const response = await twitterApiInstance.post<{
    success: boolean;
  }>("/register", {
    chain: GEM_CHAINID_MAPPING[chain],
    address,
    signature,
    publicKey,
    state,
    referralCode,
    callbackUrl,
  });
  return response.data;
}

export interface GemUser {
  address: string;
  chain: Gem_Chain_Id_Type;
  gem_amount: string;
  referral_code: string;
  twitter_followed: boolean;
  twitter_id: string;
  twitter_name: string;
  twitter_username: string;
}

export async function getUser({ state }: { state: string }) {
  try {
    const response = await twitterApiInstance.post<{
      success: boolean;
      data: GemUser;
    }>("/get_user", {
      state,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function follow({ state }: { state: string }) {
  try {
    const response = await twitterApiInstance.post<{
      success: boolean;
    }>("/follow", {
      state,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return e as any;
  }
}

export type LeaderBoardUser = Pick<
  GemUser,
  "twitter_username" | "chain" | "address" | "gem_amount"
> & {
  rank: number;
};

export type LeaderBoardDataResponseBody = {
  totalUsersNum: string;
  users: LeaderBoardUser[];
  user?: LeaderBoardUser;
};

export async function getLeaderboardData({
  twitterId,
}: {
  twitterId?: string;
}) {
  try {
    const response = await twitterApiInstance.get<{
      success: boolean;
      data: LeaderBoardDataResponseBody;
    }>("/leaderboard_data", {
      params: {
        twitterId,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getLeaderboardDataByState({ state }: { state?: string }) {
  const user = state ? await getUser({ state }) : undefined;
  return await getLeaderboardData({ twitterId: user?.data.twitter_id });
}

export async function getAuthorizeUrl() {
  try {
    const response = await twitterApiInstance.get<{
      data: string;
    }>("/authorize");
    const url = new URL(response.data.data);
    return url.toString();
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getUserByChainAddress(
  chain: Gem_Chain_Id_Type,
  address: string,
) {
  try {
    const response = await twitterApiInstance.get<{
      data: GemUser;
      success: boolean;
    }>("/get_user_by_chain_address", {
      params: {
        chain,
        address,
      },
    });
    return response.data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}
