import { useState, useEffect, ImgHTMLAttributes } from "react";

function ImageWithLoader({
  src,
  className,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) {
  const [loadedSrc, setLoadedSrc] = useState<string | null>(null);

  useEffect(() => {
    setLoadedSrc(null);
    if (src) {
      const handleLoad = () => {
        setLoadedSrc(src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [src]);

  if (loadedSrc === src) {
    return <img className={className} src={src} {...props} />;
  }

  return <div className={className} {...props} />;
}

export default ImageWithLoader;
