import { twc } from "react-twc";
import {
  InputAreaContainer,
  InputRow,
  TokenInputContainer,
  TokenLabel,
} from "./styles";
import SymbolInputContext from "../../context/SymbolInputContext";
import TokenInputContext from "../../context/TokenInputContext";
import {
  isDigit,
  isInputDigit,
  isMaxDecimals,
  removePrefix0,
} from "../../utils/regex";
import { useEffect, useMemo, useState } from "react";
import Big from "big.js";
import clsx from "clsx";
import RestakeTabContext from "../../context/RestakeTabContext";
import { formatShownDigit } from "../../utils/number";
import toLocaleString from "../../utils/toLocaleString";
import { useTranslation } from "react-i18next";
import { getPriceInUsd } from "../../utils/price";
import useAllstakeSdk from "../../hooks/useAllstakeSdk";
import useSWR from "swr";
import useShownBalance from "../../hooks/useShownBalance";

const { useSymbolInputTracked } = SymbolInputContext;
const { useTokenInputTracked } = TokenInputContext;
const { useRestakeTabTracked } = RestakeTabContext;
function TokenAmountInput() {
  const [symbolInput] = useSymbolInputTracked();
  const [tokenInput, setTokenInput] = useTokenInputTracked();
  const [error, setError] = useState("");
  const [restakeTabTracked] = useRestakeTabTracked();
  const sdk = useAllstakeSdk();
  const { data: price } = useSWR(
    `getPriceInUsd_${symbolInput?.address}_${symbolInput?.symbol}_${!!sdk ? "true" : "false"}`,
    async () => {
      if (!sdk || !symbolInput) return undefined;
      return await getPriceInUsd(sdk, symbolInput.address, symbolInput.symbol);
    },
  );
  const tokenInputInUsd = useMemo(() => {
    if (!isDigit(tokenInput) || !price) {
      return "-";
    }
    return toLocaleString(price.times(tokenInput).toFixed(), 2);
  }, [tokenInput, price]);
  const shownBalance = useShownBalance();
  // empty error when toggle tab
  useEffect(() => {
    setError("");
    setTokenInput("");
  }, [restakeTabTracked, symbolInput]);
  const { t } = useTranslation();
  if (!symbolInput) return null;
  return (
    <InputAreaContainer>
      <TokenLabel>
        {t("restake.enterAmount", {
          amt: symbolInput.symbol,
        })}
      </TokenLabel>
      <TokenInputContainer
        className={clsx("flex-col gap-3", error !== "" && "!border-[#D92D20]")}
      >
        <InputRow>
          <TokenAmountInputStyle
            value={tokenInput}
            onChange={(e) => {
              setError("");
              const value = removePrefix0(e.target.value);
              if (
                (isInputDigit(value) && !isDigit(value)) ||
                (isDigit(value) && isMaxDecimals(value, symbolInput.decimals))
              ) {
                if (
                  restakeTabTracked === "deposit" &&
                  isDigit(value) &&
                  Big(value).lt(symbolInput.minAmount)
                ) {
                  setError(
                    t("error.minimumAmount", {
                      minAmount: symbolInput.minAmount,
                      symbol: symbolInput.symbol,
                    }),
                  );
                }
                setTokenInput(value);
              }
            }}
            placeholder={`$${symbolInput.symbol} amount`}
          />
          <MaxButton
            onClick={() => {
              setError("");
              if (shownBalance && isDigit(shownBalance)) {
                if (
                  restakeTabTracked === "deposit" &&
                  shownBalance &&
                  Big(shownBalance).lt(symbolInput.minAmount)
                ) {
                  setError(
                    t("error.minimumAmount", {
                      minAmount: symbolInput.minAmount,
                      symbol: symbolInput.symbol,
                    }),
                  );
                }
                setTokenInput(shownBalance);
              }
            }}
          >
            {t("restake.max")}
          </MaxButton>
        </InputRow>
        <InputRow>
          <SubParagraph>${tokenInputInUsd}</SubParagraph>
          <SubParagraph>
            {t("restake.balance")}: {formatShownDigit(shownBalance)}
          </SubParagraph>
        </InputRow>
      </TokenInputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputAreaContainer>
  );
}

const ErrorMessage = twc.div`
  text-[#D92D20] text-sm mt-1
`;

const MaxButton = twc.button`
  px-4 py-1 border border-white border-opacity-10
  text-sm font-semibold rounded-md
`;

const TokenAmountInputStyle = twc.input`
  flex-1 text-[28px] font-bold 
  outline-none bg-transparent
  placeholder-opacity-30
  placeholder-white
`;

const SubParagraph = twc.div`
  text-white text-opacity-40
  text-sm
`;
export default TokenAmountInput;
